<template>
  <!-- sidebar -->
  <div class="bg-background-light-light fixed h-screen p-4 hidden lg:block z-20 drop-shadow-lg overflow-hidden">
    <div class="h-11 w-11 ml-2 mt-5 mb-20">
      <img :src="deltaLogo" alt="the delta logo" class="w-137px" />
    </div>
    <div v-if="authenticationData?.ventureProfileDone">
      <router-link
        v-for="item in navigationItems"
        :id="`google-navigation-desktop-${convertToLowercaseWithDashes(item.name)}`"
        :key="item.name"
        :data-cy="`navigation-desktop-${convertToLowercaseWithDashes(item.name)}`"
        :to="item.linkType.id === 2 ? item.link : ''"
        @click="setCurrentNavigationItem(item)"
      >
        <div
          :class="[
            selectedRoute === item.link || selectedRoute.includes(item.flowId as string) ? 'bg-gray-200' : 'bg-none',
            'w-full rounded-lg px-4 py-2 mb-4 min-w-[200px] hover:bg-gray-200',
          ]"
        >
          <img v-if="item.navigationIconUrl" :src="item.navigationIconUrl" :alt="item.name" class="w-6 h-6 inline-block" />
          <p
            :class="[
              selectedRoute === item.link || selectedRoute.includes(item.flowId as string) ? 'light-7-1-plus' : 'text-text-light-3-1',
              item.navigationIconUrl ? 'ml-4' : 'ml-10',
              'text-postscript whitespace-nowrap tracking-widest font-medium text-center inline-block',
            ]"
          >
            {{ item.name.toUpperCase() }}
          </p>
        </div>
      </router-link>
    </div>
  </div>
  <!-- mobile nav -->
  <div v-if="authenticationData?.ventureProfileDone" class="block lg:hidden z-40">
    <div class="flex justify-between">
      <app-button-icon
        id="google-navigation-mobile-toggle-menu"
        :icon-image="showMenu ? closeIcon : menuIcon"
        :button-size="ButtonSize.LARGE"
        :button-colour="ButtonColours.WHITE"
        data-cy="navigation-mobile-toggle-menu"
        class="w-5 ml-5 mt-4 z-10 fixed"
        @click="toggleMenu()"
      ></app-button-icon>
    </div>
    <div v-if="showMenu" class="fixed h-screen w-screen bg-white z-10 mt-[75px] px-5">
      <router-link
        v-for="item in navigationItems"
        :id="`google-navigation-mobile-${convertToLowercaseWithDashes(item.name)}`"
        :key="item.name"
        :data-cy="`navigation-mobile-${convertToLowercaseWithDashes(item.name)}`"
        :to="item.linkType.id === 2 ? item.link : ''"
        @click="setCurrentNavigationItem(item)"
      >
        <div
          :class="[
            selectedRoute === item.link || selectedRoute.includes(item.flowId as string) ? 'bg-gray-300' : 'bg-none',
            'w-full h-11 rounded-lg p-4 mb-5 min-w-[200px]',
          ]"
        >
          <p
            :class="[
              selectedRoute === item.link || selectedRoute.includes(item.flowId as string) ? 'light-7-1-plus' : 'text-text-light-3-1',
              'text-postscript whitespace-nowrap tracking-widest font-medium',
            ]"
          >
            {{ item.name.toUpperCase() }}
          </p>
        </div>
      </router-link>
      <app-button
        id="google-open-calendly"
        data-cy="button-open-calendly"
        name="open-calendly"
        :text="t('contactSales.salesCallButtonText')"
        size="small"
        :color="`black`"
        :text-color="`white`"
        :style="`roundedFull`"
        class="mt-10 block md:hidden"
        @click="openCalendlylink"
      />
      <div
        :id="`google-navigation-mobile-${convertToLowercaseWithDashes(signOutItem.title)}`"
        :data-cy="`navigation-mobile-${convertToLowercaseWithDashes(signOutItem.title)}`"
        class="flex fixed h-11 p-3 bottom-8"
        @click="signOutItem.action"
      >
        <img :src="signOutItem.icon" :alt="`${signOutItem.title} icon`" class="m-auto" />
        <p class="text-xs pl-4 mt-1 text-text-light-3-1 font-semibold tracking-widest">
          {{ signOutItem.title.toUpperCase() }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref, UnwrapRef, watch } from "vue";

import i18n from "@/plugins/vue-i18n";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/auth";
import { useFlowStore } from "@/stores/flow";
import { useProfileStore } from "@/stores/profile";
import { useVosApplicationStore } from "@/stores/vos-application";

import { NavigationItem } from "@/stores/navigation/types";

import router from "@/router";

import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppButtonIcon from "@/components/ui/buttons/AppButtonIcon.vue";

import { ButtonColours, ButtonSize } from "@/common/constants/buttons";

import { convertToLowercaseWithDashes } from "@/common/utils/string-utils";

import closeIcon from "@/assets/images/icons/navigation/close.svg?url";
import deltaLogo from "@/assets/images/logos/logo-only.svg?url";
import menuIcon from "@/assets/images/icons/navigation/menu.svg?url";
import signOutIcon from "@/assets/images/icons/navigation/signout.svg?url";
import homeIcon from "@/assets/images/icons/navigation/home.svg?url";
import profileIcon from "@/assets/images/icons/navigation/profile.svg?url";
import { ApplicationType } from "@/common/constants/component/vosApplication";
import { VosApplication } from "@/stores/vos-application/types";

const { t } = i18n.global;

const emit = defineEmits(["updateMenuState"]);

const currentRoute = computed(() => router.currentRoute.value.fullPath);
const selectedRoute: Ref<UnwrapRef<string>> = ref("");

const authStore = useAuthStore();
const flowStore = useFlowStore();
const vosApplicationStore = useVosApplicationStore();
const profileStore = useProfileStore();

const { authenticationData } = storeToRefs(authStore);
const { allApplications } = storeToRefs(vosApplicationStore);

const showMenu = ref(false);

const navigationItems = ref([] as NavigationItem[]);

const signOutItem = { title: t("general.logOut"), icon: signOutIcon, action: () => logout() };

const openCalendlylink = async () => {
  window.open(import.meta.env.VITE_APP_SALES_DEFAULT_CALENDLY, "Sales Person Calendly", "noopener");
};

async function setCurrentNavigationItem(routeObject: NavigationItem) {
  if (routeObject.linkType.name === ApplicationType.EXTERNAL_LINK && routeObject.link) {
    window.open(routeObject.link, "External Link", "noopener");
  } else if (routeObject.linkType.name === ApplicationType.FLOW) {
    selectedRoute.value = routeObject.flowId?.toString() as string;
    await flowStore.setCurrentFlowOrderNumber(0);
    await router.push({ name: "generic-flow", params: { flowId: routeObject.flowId } });
    location.reload();
  } else if (routeObject.link !== null) {
    selectedRoute.value = routeObject.link;
  }
  if (showMenu.value) toggleMenu();
}

function logout() {
  profileStore.logout();
}

function toggleMenu() {
  emit("updateMenuState", !showMenu.value);
  showMenu.value = !showMenu.value;
}

const setNavigationItems = () => {
  navigationItems.value = (allApplications.value as VosApplication[])
    .filter((item: VosApplication) => item.isShownOnNavBar)
    .map((item) => ({
      name: item.name,
      order: item.order,
      link: item.link,
      navigationIconUrl: item.navigationIconUrl,
      flowId: item.flowId?.toString(),
      linkType: {
        id: item.type.id,
        name: item.type.name,
      },
    }));

  const dashboardNavItem = {
    name: "Dashboard",
    order: 0,
    link: "/dashboard",
    navigationIconUrl: homeIcon,
    linkType: {
      id: 2,
      name: "InternalLink",
    },
  };
  const profileNavItem = {
    name: "Profile View",
    order: navigationItems.value.length + 1,
    link: "/venture-profile",
    navigationIconUrl: profileIcon,
    linkType: {
      id: 2,
      name: "InternalLink",
    },
  };

  navigationItems.value.unshift(dashboardNavItem);
  navigationItems.value.push(profileNavItem);
};

onMounted(async () => {
  await vosApplicationStore.getVosApplications();
  setNavigationItems();
  selectedRoute.value = currentRoute.value;
});

watch(
  () => currentRoute.value,
  (value, oldValue) => {
    if (value !== oldValue) {
      selectedRoute.value = currentRoute.value;
    }
  },
);
</script>
