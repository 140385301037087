import { AxiosResponse } from "axios";
import { createAxiosRequest } from "../api-base";

const resource = "partners";

/**
 * Gets a list of all Delta partners
 * @param {SearchText} SearchText - SearchText
 * @returns An AxiosResponse object
 */
export async function getPartners(SearchText: string): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).get("/", {
    params: {
      SearchText: SearchText,
    },
  });
}

/**
 * Returns partner information for provided id
 * @param {number} id - number - the id of the partner you want to get
 * @returns An AxiosResponse object
 */
export async function getPartner(id: number): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).get(`/${id}`);
}
