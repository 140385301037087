export enum ApplicationStatus {
  ACTIVE = "Active",
  COMING_SOON = "ComingSoon",
}

export enum ApplicationType {
  FLOW = "Flow",
  INTERNAL_LINK = "InternalLink",
  EXTERNAL_LINK = "ExternalLink",
  EMBEDDED_LINK = "EmbeddedLink",
}
