import { AxiosResponse } from "axios";
import { createAxiosRequest } from "../api-base";
import { ScopeAcceptBody } from "@/api/scope/types";

const resource = "scopes";

/**
 * Enquire about the scope
 * @param {number} scopeId - The id of the scope you want to enquire.
 * @returns AxiosResponse
 */
export async function enquireScope(scopeId: number): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).put(`/${scopeId}`, { id: scopeId });
}

/**
 * Gets a list of all scopes available for the venture
 * method on it
 * @returns An AxiosResponse object
 */
export async function getScopes(): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).get("/");
}

/**
 * Returns scope information for provided id
 * @param {number} id - number - the id of the scope you want to get
 * @returns An AxiosResponse object
 */
export async function getScope(id: number): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).get(`/${id}`);
}

/**
 * It accepts the scope and sends through the user's information to be saved
 * @param {ScopeAcceptBody} scopeAcceptBody - ScopeAcceptBody
 * @returns AxiosResponse
 */
export async function acceptScope(scopeAcceptBody: ScopeAcceptBody): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).post(`/${scopeAcceptBody.id}/accept`, scopeAcceptBody);
}

/**
 * Returns pdf information for provided id
 * @param {number} id - number - the id of the scope you want to get the pdf for
 * @returns An AxiosResponse object
 */
export async function downloadScopeOfWorkPdf(id: number): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).get(`/${id}/preview`, {
    responseType: "blob",
  });
}
