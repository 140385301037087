/* eslint-disable @typescript-eslint/no-explicit-any */
import useSentry from "@/plugins/sentry";
import ObjectUtils from "@/common/utils/object-utils";
import { AxiosResponse } from "axios";
import router from "@/router";
import ErrorType from "@/common/constants/error";
import { showApiError } from "@/common/utils/notification-utils";

/**
 * It takes an AxiosResponse object as an argument, and returns the same AxiosResponse object
 * @param res - AxiosResponse<any>
 * @returns The response object is being returned.
 */
export const responseInterceptor = (res: AxiosResponse<any>): AxiosResponse<any> => {
  return res;
};

/**
 * If the response is an error, log it to Sentry and redirect to the error page
 * @param {any} error - any: The error object that is returned from the API call.
 * @returns A function that takes an error and returns a promise that rejects with the error.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onRejected = (error: any): Promise<never> => {
  const { captureApiError } = useSentry();
  /**
   * If the response contains data, trim it so that we protect sensitive information.
   * @param res The axios response
   */
  const trimData = (res: AxiosResponse) => {
    const clone = ObjectUtils.DeepClone(res);
    if (clone.data.data) delete clone.data.data;
    return clone;
  };

  let errorType = null;

  if (error.response === undefined) {
    errorType = ErrorType.SERVICE_UNAVAILABLE;
  } else {
    switch (error.response.status) {
      case 400:
        {
          showApiError(error.response.data.data);
        }
        break;
      case 401:
        errorType = ErrorType.UNAUTHORIZED;
        break;
      case 403:
        errorType = ErrorType.FORBIDDEN;
        break;
      case 404:
        errorType = ErrorType.NOT_FOUND;
        break;
      case 500:
        errorType = ErrorType.SERVER_ERROR;
        break;
    }
  }

  captureApiError({
    response: trimData(error.response),
  });

  if (errorType) {
    router.push({ name: "error", params: { errorType: errorType } });
  }

  return Promise.reject(error);
};
