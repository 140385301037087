<template>
  <app-modal ref="appModal" dialog-panel-classes="relative bg-background-delta max-h-screen lg:max-w-748px">
    <enquire-products @close-modal="closeEnquireModal"></enquire-products>
  </app-modal>
</template>

<script setup lang="ts">
import { ref } from "vue";

import AppModal from "@/components/ui/modals/AppModal.vue";
import EnquireProducts from "@/components/enquire/EnquireProducts.vue";

const appModal = ref();

function openEnquireModal() {
  appModal.value.openModal();

  window.dataLayer?.push({
    event: "view_enquiries_button_click",
    open_enquiry: "open-enquiry",
  });
}

function closeEnquireModal() {
  appModal.value.closeModal();
}

defineExpose({
  openEnquireModal: openEnquireModal,
});
</script>
