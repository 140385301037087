<template>
  <button :disabled="props.isDisabled" :class="buttonClasses" type="button" @click="onClick()">
    <img :src="props.iconImage" class="m-auto" alt="icon-image" />
  </button>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getButtonColourClass, getButtonSizeClass } from "@/common/utils/button-utils";
import { ButtonColours, ButtonSize } from "@/common/constants/buttons";

const emits = defineEmits(["onClick"]);

const props = defineProps({
  buttonSize: {
    type: String,
    required: true,
    validator(value: string) {
      return Object.values(ButtonSize).includes(value as ButtonSize);
    },
  },
  buttonColour: {
    type: String,
    required: true,
    validator(value: string) {
      return Object.values(ButtonColours).includes(value as ButtonColours);
    },
  },
  iconImage: {
    type: String,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

const buttonClasses = computed(() => {
  const buttonSizeClass = getButtonSizeClass(props.buttonSize, true);
  const buttonColourClass = getButtonColourClass(props.buttonColour);

  return `${buttonSizeClass} ${buttonColourClass}`;
});

function onClick() {
  emits("onClick");
}
</script>
