import { defineStore } from "pinia";
import { createActionHelpers } from "@/stores/helpers/global-loader";
import { ImageType, VentureData, VentureParams, VentureState } from "./types";
import {
  getVenture,
  updateVenture,
  uploadLogo,
  deleteLogo,
  ventureOnboarded,
  uploadHeader,
  deleteHeader,
  getVentureById,
  getVentures,
  updateVentureTags,
  uploadImage,
  deleteImage,
} from "@/api/venture/venture-api";
import { Venture } from "@/api/venture/types";
import { showSuccess } from "@/common/utils/notification-utils";
import i18n from "@/plugins/vue-i18n";
import { HttpStatusCode } from "axios";

const { t } = i18n.global;

const { startLoading } = createActionHelpers();

export const useVentureStore = defineStore("venture", {
  state: (): VentureState => ({
    venture: null,
    selectedVenture: {} as VentureData,
    ventureList: [],
    ventureCreated: !!localStorage.getItem("ventureCreated") || false,
    ventureParams: {
      PageNo: 1,
      PageSize: 10,
      Enabled: true,
      SearchText: "",
    } as VentureParams,
  }),
  actions: {
    async getVenture() {
      const response = await startLoading("venture", getVenture());
      this.venture = response.data.data;
    },
    async getVentureById(id: number) {
      const response = await startLoading("venture", getVentureById(id));
      this.selectedVenture = response.data.data;
    },
    async getVentureList(params?: Partial<VentureParams>) {
      const mergedParams = { ...this.ventureParams, ...params };
      this.ventureList = (await getVentures(mergedParams)).data.data;
      this.ventureParams = mergedParams;
    },
    async updateVenture(venture: Venture) {
      await startLoading("venture", updateVenture(venture));
    },
    async updateVentureTags(ventureId: number, tags: string) {
      await startLoading("venture", updateVentureTags(ventureId, tags));
    },
    async uploadImage(form: FormData) {
      const response = await startLoading("venture", uploadImage(form));
      let field = "";
      if (this.venture && response.status === HttpStatusCode.Created) {
        const { imageUrl, imageType } = response.data.data;
        switch (imageType as ImageType) {
          case ImageType.HEADER:
            this.venture.headerImageUrl = imageUrl;
            field = t("general.header");
            break;
          case ImageType.LOGO:
            this.venture.profileImageUrl = imageUrl; // TODO: Refactor prop name in future
            field = t("general.logo");
            break;
          case ImageType.CONTACT_PERSON:
            this.venture.contactImageUrl = imageUrl;
            field = t("general.contactImage");
            break;
          case ImageType.PROFILE:
            this.venture.profileImageUrl = imageUrl;
            field = t("general.logo"); // TODO: Refactor in future
            break;
          case ImageType.ICON:
          case ImageType.IMAGE:
          default:
            break;
        }
        showSuccess(t(`general.updatedField`, { field }));
      }
    },
    async deleteImage(imageType: ImageType) {
      const response = await startLoading("venture", deleteImage(imageType));
      let field = "";
      if (this.venture && response.status === HttpStatusCode.Ok) {
        switch (imageType as ImageType) {
          case ImageType.HEADER:
            this.venture.headerImageUrl = null;
            field = t("general.header");
            break;
          case ImageType.LOGO:
            this.venture.profileImageUrl = null; // TODO: Refactor prop name in future
            field = t("general.logo");
            break;
          case ImageType.CONTACT_PERSON:
            this.venture.contactImageUrl = null;
            field = t("general.contactImage");
            break;
          case ImageType.PROFILE:
            this.venture.profileImageUrl = null;
            field = t("general.logo"); // TODO: Refactor in future
            break;
          case ImageType.ICON:
          case ImageType.IMAGE:
          default:
            break;
        }
        showSuccess(t(`general.updatedField`, { field }));
      }
    },
    async uploadLogo(logo: File) {
      await startLoading("venture", uploadLogo(logo));
      showSuccess(t(`general.updatedField`, { field: t("general.logo") }));
    },
    async deleteLogo() {
      await startLoading("venture", deleteLogo());
      showSuccess(t(`general.updatedField`, { field: t("general.logo") }));
    },
    async uploadHeader(header: File) {
      await startLoading("venture", uploadHeader(header));
      showSuccess(t(`general.updatedField`, { field: t("general.header") }));
    },
    async deleteHeader() {
      await startLoading("venture", deleteHeader());
      showSuccess(t(`general.updatedField`, { field: t("general.header") }));
    },
    async ventureOnboarded() {
      await startLoading("venture", ventureOnboarded());
    },
  },
});
