import api from "@/configs/api";
import { useAuthStore } from "@/stores/auth";
import axios, { AxiosInstance } from "axios";
import { storeToRefs } from "pinia";
import { onRejected, responseInterceptor } from "./interceptors";

/**
 * It creates an axios instance with the base url of the resource and the version of the api
 * @param {string} resource - The resource you want to access. For example, if you want to access the
 * /users endpoint, you would pass in 'users'
 * @param [version=1] - The version of the API you're using.
 * @param contentType
 * @param [secured=true] - If the request is secured or not.
 * @returns An AxiosInstance
 */
export function createAxiosRequest(resource: string, version = 1, contentType: "json" | "form" = "json", secured = true): AxiosInstance {
  const baseUrl = `${api.baseUrl}/v${version}/${resource}`;
  let axiosRequest = axios.create({
    baseURL: baseUrl,
  });

  const authStore = useAuthStore();
  const { authenticationData } = storeToRefs(authStore);

  if (secured) {
    axiosRequest = axios.create({
      baseURL: baseUrl,
      headers: {
        Authorization: `Bearer ${authenticationData.value?.accessToken}`,
        "Content-Type": contentType === "json" ? "application/json; charset=utf-8" : "multipart/form-data",
      },
    });
  }

  axiosRequest.interceptors.response.use(responseInterceptor, onRejected);

  return axiosRequest;
}
