import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/onboarding",
    name: "onboarding",
    component: () => import("@/views/onboarding/OnboardingPage.vue"),
    children: [
      {
        path: "welcome",
        name: "welcome",
        component: () => import("@/views/onboarding/WelcomeView.vue"),
        meta: {
          layout: "login",
          requiresAuth: false,
        },
      },
      {
        path: "venture-profile",
        name: "onboarding-venture-profile",
        component: () => import("../views/onboarding/VentureProfile.vue"),
        meta: {
          requiresAuth: true,
          layout: "logoOnly",
        },
      },
      {
        path: "gap-analysis",
        name: "gap-analysis",
        component: () => import("@/views/onboarding/GapAnalysisForm.vue"),
        meta: {
          requiresAuth: true,
          layout: "logoOnly",
        },
      },
      {
        path: "thank-you",
        name: "thank-you",
        component: () => import("@/views/onboarding/ThankYou.vue"),
        meta: {
          layout: "onboard",
          requiresAuth: true,
        },
      },
    ],
  },
] as RouteRecordRaw[];
