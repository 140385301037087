<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-50" @close="closeModal">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-greyscale-dark-15 bg-opacity-3" />
      </TransitionChild>

      <div class="fixed inset-0">
        <div :class="[centerModal ? 'justify-center h-full' : 'w-full h-full lg:justify-end', 'flex items-center text-center']">
          <DialogPanel
            :class="[
              props.dialogPanelClasses,
              centerModal ? '' : 'h-full',
              'w-full transform overflow-hidden text-left transition-all overflow-y-auto',
            ]"
          >
            <slot></slot>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from "@headlessui/vue";

const props = defineProps({
  dialogPanelClasses: {
    type: String,
    required: false,
    default: "",
  },
  centerModal: {
    type: Boolean,
    default: false,
  },
});

const isOpen = ref(false);

function closeModal() {
  isOpen.value = false;
}

function openModal() {
  isOpen.value = true;
}

defineExpose({
  closeModal,
  openModal,
});
</script>
