export default {
  advisorsPageTitle: "Advisors",
  dashboardPageTitle: "Welcome",
  directoryPageTitle: "Directory",
  marketplacePageTitle: "Marketplace",
  ndaPageTitle: "Non-Disclosure Agreement",
  privacyPolicyPageTitle: "Privacy Policy",
  proposalsPageTitle: "My Scope of Works",
  resourcesPageTitle: "Resources",
  saasToolsPageTitle: "SaaS Tools",
  ventureProfilePageTitle: "Venture Profile",
  venturesPageTitle: "Directory",
};
