<template>
  <div class="min-h-full">
    <main>
      <div class="relative flex flex-col md:flex-row">
        <slot></slot>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts"></script>
