import { Response } from "@/api/types";
import { AxiosResponse } from "axios";
import { createAxiosRequest } from "../api-base";
import { Venture } from "./types";
import { ImageType, VentureData, VentureParams } from "@/stores/venture/types";

const resource = "ventures";

/**
 * Updates a venture according to the given ventureId and Venture information
 * @param {Venture} venture - Venture - this is the object that we're going to be updating.
 * @returns AxiosResponse
 */
export async function updateVenture(venture: Venture): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).put(`/${venture.id}`, venture);
}

/**
 * Updates a venture's tags according to the given ventureId and tags
 * @param {number} ventureId - number - ID of venture to update
 * @param {string} tags - string - a comma separated string representing tags for a venture.
 * @returns AxiosResponse
 */
export async function updateVentureTags(ventureId: number, tags: string): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).put("/tags", { id: ventureId, tags });
}

/**
 * Retrieves a venture that is connected to the logged in user
 * @returns An AxiosResponse object
 */
export async function getVenture(): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).get("/");
}

/**
 * Gets a single venture by Id
 * @returns An AxiosResponse object
 */
export async function getVentureById(ventureId: number): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).get(`/${ventureId}`);
}

/**
 * Returns ventures used for listing, filtering and searching paged card data.
 *
 * @param {Object<VentureParams>} params
 *
 * @returns VentureData[]
 */
export async function getVentures(params: VentureParams): Promise<AxiosResponse<Response<VentureData[]>>> {
  return createAxiosRequest(resource, 1).get("/paged", { params });
}

/**
 * It uploads a logo to the server for the venture
 * @param {File} logo - File - this is the file that you want to upload.
 * @returns AxiosResponse
 */
export async function uploadLogo(logo: File): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1, "form").post("/profile-image", logo);
}

/**
 * It deletes the logo for the venture
 * @returns AxiosResponse
 */
export async function deleteLogo(): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).delete("/profile-image");
}

/**
 * It uploads an image to the server for the venture, based on ImageType
 * @param {FormData} body - File - this is the file that you want to upload.
 * @returns AxiosResponse
 */
export async function uploadImage(body: FormData): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1, "form").post("/image", body);
}

/**
 * It deletes an image related to the venture, based on ImageType
 * @returns AxiosResponse
 */
export async function deleteImage(imageType: ImageType): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).delete("/image", { data: { type: imageType } });
}

/**
 * It uploads a header image to the server for the venture
 * @param {File} header - File - this is the file that you want to upload.
 * @returns AxiosResponse
 */
export async function uploadHeader(header: File): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1, "form").post("/header-image", header);
}

/**
 * It deletes the header image for the venture
 * @returns AxiosResponse
 */
export async function deleteHeader(): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).delete("/header-image");
}

/**
 * Sets the onboarded flag for the venture to ensure we do not show the user the onboarding flow again
 * @returns AxiosResponse
 */
export async function ventureOnboarded(): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).post("/onboarded");
}
