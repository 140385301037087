export default {
  acceptProposal: "Accept proposal",
  acceptance: "Acceptance",
  addSignature: "Add signature",
  agreedDate: "Date agreed",
  agreedToBy: "Agreed to by",
  congratulations: "Congratulations! You’ve accepted this proposal!",
  contact: "We will be in contact to begin the next steps.",
  disclaimer: "By accepting this proposal, you agree to the scope of work as defined within this proposal and in line with The Delta's Master",
  downloadAcceptedPdf: "Download Accepted PDF",
  emptyMyProposals: "You currently have no pending Scope of Works",
  emptyScopeOfWorksSection: "You currently have no {field} Scope of Works",
  fieldScope: "{field} scopes",
  newProposal: "New Proposal",
  readyToAccept: "Ready to accept",
  readyToSign: "Ready to sign?",
  serviceAgreement: "Service Agreement | Services Agreement",
  signature: "Signature",
  status: {
    active: "Active",
    complete: "Complete",
    pending: "Pending",
    ready: "Ready for Review",
  },
  viewVentureMarketplace: "View venture marketplace",
};
