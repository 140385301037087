import { POSITION, useToast } from "vue-toastification";

const toast = useToast();

/**
 * A function to trigger the success toast message
 * @param {string} message - The message to display
 */
export function showSuccess(message: string): void {
  toast.success(message, { timeout: 3000 });
}

/**
 * A function to triggers the API error toast message
 * It takes an object, and if it's empty, it shows a generic error message, otherwise it shows the
 * error message from the object
 * @param data - Record<string, unknown>
 */
export function showApiError(data: Record<string, unknown>): void {
  const message = !data ? "An error occurred." : `Error: ${Object.values(data)}`;
  toast.error(message, { timeout: 3000 });
}

/**
 * A function to trigger the error toast message
 * @param {string} message - The message to display
 */
export function showError(message: string): void {
  toast.error(message, { timeout: 3000 });
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
/**
 * A function to trigger the Firebase information toast message
 * @param {any} content - The content of the toast.
 */
export function showFirebaseInfo(content: any): void {
  toast.info(content, { timeout: false, position: POSITION.TOP_RIGHT });
}
