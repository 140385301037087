<template>
  <div class="h-screen w-screen relative image-class" :style="`background-image: url(${backgroundImage})`">
    <div class="bg-greyscale-dark-15 bg-opacity-60 h-screen w-screen absolute top-0"></div>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

import { getBreakpoint } from "@/common/utils/breakpoint-utils";

import dashboardMobile from "@/assets/images/onboarding/dashboard-mobile-348.png?url";
import dashboardSmall from "@/assets/images/onboarding/dashboard-sm-640.png?url";
import dashboardMedium from "@/assets/images/onboarding/dashboard-md-768.png?url";
import dashboardLarge from "@/assets/images/onboarding/dashboard-lg-1024.png?url";
import dashboardXLarge from "@/assets/images/onboarding/dashboard-xl-1280.png?url";
import dashboardXXLarge from "@/assets/images/onboarding/dashboard-2xl-1536.png?url";

const backgroundImage = ref("");

function getBackgroundImage() {
  const breakpoint = getBreakpoint();

  switch (breakpoint) {
    case "2xl":
      backgroundImage.value = dashboardXXLarge;
      break;
    case "xl":
      backgroundImage.value = dashboardXLarge;
      break;
    case "lg":
      backgroundImage.value = dashboardLarge;
      break;
    case "md":
      backgroundImage.value = dashboardMedium;
      break;
    case "sm":
      backgroundImage.value = dashboardSmall;
      break;
    default:
      backgroundImage.value = dashboardMobile;
      break;
  }
}

onMounted(async () => {
  getBackgroundImage();

  addEventListener("resize", () => {
    getBackgroundImage();
  });
});
</script>

<style>
.image-class {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
}
</style>
