import { ButtonColours, ButtonSize } from "@/common/constants/buttons";

/**
 * "Given a button colour, return the corresponding CSS class."
 * @param {string} buttonColour - This is the colour of the button.
 * @returns A string
 */
export const getButtonColourClass = (buttonColour: string): string => {
  let buttonColourClass = "";

  switch (buttonColour) {
    case ButtonColours.WHITE:
      buttonColourClass = "btn-white";
      break;
    case ButtonColours.WHITE_WITH_BORDER:
      buttonColourClass = "btn-white black-border-2";
      break;
    case ButtonColours.GRAY:
      buttonColourClass = "btn-gray";
      break;
    case ButtonColours.BLACK:
      buttonColourClass = "btn-black";
      break;
    case ButtonColours.GREEN:
      buttonColourClass = "btn-green drop-shadow-btn-green";
      break;
    case ButtonColours.DISABLED:
      buttonColourClass = "btn-disabled";
      break;
    case ButtonColours.NONE:
      buttonColourClass = "btn-none";
      break;
    case ButtonColours.NONE_WITH_BORDER:
      buttonColourClass = "btn-none gray-border-2";
      break;
  }

  return buttonColourClass;
};

/**
 * It returns a string that represents the class name of the button size
 * @param {string} buttonSize - string - The size of the button.
 * @param {boolean} isButtonIconOnly - boolean - This is a boolean value that determines if the button
 * is an icon only button.
 * @returns A string
 */
export const getButtonSizeClass = (buttonSize: string, isButtonIconOnly: boolean): string => {
  let buttonSizeClass = "";

  switch (buttonSize) {
    case ButtonSize.SMALL:
      buttonSizeClass = isButtonIconOnly ? "btn-icon-small" : "btn-small";
      break;
    case ButtonSize.MEDIUM:
      buttonSizeClass = isButtonIconOnly ? "btn-icon-medium" : "btn-medium";
      break;
    case ButtonSize.LARGE:
      buttonSizeClass = isButtonIconOnly ? "btn-icon-large" : "btn-large";
      break;
  }

  return buttonSizeClass;
};
