/* eslint-disable @typescript-eslint/no-explicit-any */
class ArrayUtils {
  /**
   * It takes an array of objects, and returns a new array of objects with duplicate objects removed
   * @param arr - The array you want to filter
   * @param {string} key - The key to use to determine uniqueness.
   * @returns An array of unique objects based on the key provided.
   */
  public static UniqueBy(arr: Array<any>, key: string): Array<any> {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  /**
   * It takes an array of strings, and returns a filtered array of strings with duplicate strings removed
   * @param array - The array you want to filter
   * @returns An array of unique strings.
   */
  public static Uniq = (array: string[]): string[] => {
    return array.filter((el, index, arr) => index == arr.indexOf(el));
  };
}

export default ArrayUtils;
