import { defineStore } from "pinia";
import { createActionHelpers } from "@/stores/helpers/global-loader";
import { VosApplication, VosApplicationState } from "./types";
import { getVosApplications } from "@/api/vos-application/vos-application-api";

const { startLoading } = createActionHelpers();

export const useVosApplicationStore = defineStore("vosApplication", {
  state: (): VosApplicationState => ({
    allApplications: [],
    application: {} as VosApplication,
  }),
  actions: {
    async getVosApplications() {
      const response = await startLoading("vosApplication", getVosApplications());
      this.allApplications = response.data.data;
    },
    async getVosApplication(appId: number) {
      this.application = this.allApplications.find((application: VosApplication) => application.id === appId) as VosApplication;
    },
    async setDefaultVosApplicationData() {
      this.allApplications = [];
      this.application = {} as VosApplication;
    },
  },
});
