<template>
  <div
    class="grid grid-cols-[50px_1fr_50px] bg-background-light-light px-3 py-13px cursor-pointer mb-2"
    @click="openProductModal(props.data?.id || 0)"
  >
    <div class="flex place-self-center rounded-full bg-background-light-light-2 p-2 h-12 border">
      <img :src="props.partnerProductLogo || logoPlaceholder" alt="delta-logo-colour" />
    </div>
    <div class="px-2">
      <h6 class="text-greyscale-dark-5 font-medium">{{ props.data.name }}</h6>
      <p class="text-sm text-text-light-7-1 line-clamp-2">{{ props.data.partner?.name }}</p>
    </div>
    <app-button-icon
      :id="`google-${productIsEnquired ? 'add-to-enquiries' : 'remove-from-enquiries'}-${convertToLowercaseWithDashes(props.data?.name || '')}`"
      :data-cy="`button-enquire-${convertToLowercaseWithDashes(props.data?.name || '')}`"
      :button-size="ButtonSize.MEDIUM"
      :button-colour="productIsEnquired ? ButtonColours.GRAY : ButtonColours.BLACK"
      class="place-self-center rounded-full"
      :icon-image="productIsEnquired ? trashIcon : PlusIcon"
      @click.stop="onEnquireClick"
    ></app-button-icon>
    <app-product-modal
      v-if="product"
      ref="appModal"
      :data="product"
      :is-directory-modal="true"
      :partner-product-logo="partnerProductLogo"
    ></app-product-modal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import logoPlaceholder from "@/assets/images/logos/logo-only.svg?url";
import AppButtonIcon from "@/components/ui/buttons/AppButtonIcon.vue";
import trashIcon from "@/assets/images/icons/trash-can-black.svg?url";
import PlusIcon from "@/assets/images/icons/plus-white.svg?url";
import { ButtonColours, ButtonSize } from "@/common/constants/buttons";

import AppProductModal from "@/components/products/product/AppProductModal.vue";

import { convertToLowercaseWithDashes } from "@/common/utils/string-utils";
import { ScopeProductItem } from "@/stores/scope-product/types";
import { useScopeProductStore } from "@/stores/scope-product";
import { storeToRefs } from "pinia";
import { useProductStore } from "@/stores/product";
import { RelatedProduct } from "@/stores/directories/types";

const props = defineProps({
  data: {
    type: Object as () => RelatedProduct,
    required: true,
  },
  partnerProductLogo: {
    type: String,
  },
  isDirectoryModal: {
    type: Boolean,
    default: false,
  },
});

const productStore = useProductStore();
const scopeProductStore = useScopeProductStore();

const { product } = storeToRefs(productStore);
const { scopeProducts } = storeToRefs(scopeProductStore);

const appModal = ref();

const emit = defineEmits(["onEnquireClick"]);

const productIsEnquired = computed(
  () => !!scopeProducts.value?.scopeProducts?.some((scopeProduct: ScopeProductItem) => scopeProduct.product.id === props.data?.id),
);

function onEnquireClick() {
  emit("onEnquireClick", props.data?.id);
}

async function openProductModal(id: number) {
  await productStore.getProduct(id);
  await appModal.value.openProductModal();
}
</script>
