<template>
  <app-modal ref="appModal" dialog-panel-classes="relative bg-background-light-light lg:max-w-7xl">
    <div class="absolute w-full flex justify-between p-6">
      <app-button-icon
        :id="`google-button-close-product-modal-${convertToLowercaseWithDashes(data.name)}`"
        :data-cy="`button-close-product-modal-${convertToLowercaseWithDashes(data.name)}`"
        :icon-image="closeSmallIcon"
        :button-colour="ButtonColours.GRAY"
        :button-size="ButtonSize.LARGE"
        @click="closeProductModal"
      >
      </app-button-icon>
      <app-button
        :id="`google-button-${productIsEnquired ? 'add-to' : 'remove-from'}-enquiries-from-product-details-${convertToLowercaseWithDashes(
          data.name,
        )}`"
        :data-cy="`button-${productIsEnquired ? 'add-to' : 'remove-from'}-enquiries-from-product-details-${convertToLowercaseWithDashes(data.name)}`"
        :name="`enquire-product-${convertToLowercaseWithDashes(data.name)}`"
        :text="t('products.addToEnquiry', productIsEnquired ? 0 : 1)"
        size="medium"
        :color="productIsEnquired ? 'green' : 'black'"
        text-color="white"
        @click="onEnquireClick"
      >
        <template #icon>
          <EnquiryCheck v-if="productIsEnquired" class="text-black w-5 h-5 ml-2" />
          <EnquiryAdd v-else class="text-black w-5 h-5 ml-2" />
        </template>
        >
      </app-button>
    </div>

    <div class="px-2 py-16 sm:py-8 sm:px-8 sm:px-[80px] sm:py-45px">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-5">
        <div class="col-span-1 lg:col-span-2 flex flex-col gap-y-6">
          <div class="sm:pt-10">
            <h3 v-if="props.data?.name" class="text-greyscale-dark-5 mb-5 mt-2 font-medium">
              {{ props.data.name }}
            </h3>
          </div>
          <img :src="productImage" alt="header-image" class="border rounded-10px bg-greyscale-light-15" />
          <div v-if="props.data?.description" class="flex flex-wrap">
            <h4 class="mb-6">{{ t("products.detailsText") }}</h4>
            <h6 class="text-text-light-3-2" v-html="props.data.description"></h6>
          </div>
        </div>

        <div class="col-span-1 flex flex-col text-sm">
          <div v-if="props.data?.partner" class="p-8 rounded-10px shadow-partner-main mb-14 mt-10">
            <div
              :class="[
                props.data.partner.websiteUrl ? 'cursor-pointer' : '',
                'flex items-center col-start-1 rounded-10px shadow-light-small bg-white p-5 h-[90px]',
              ]"
              @click="props.data.partner.websiteUrl ? openWebsiteLink(props.data.partner.websiteUrl) : ''"
            >
              <img :src="props.data.partner.logoUrl || logoPlaceholder" alt="partner product icon" class="h-11 w-11" />
              <div class="flex flex-col justify-items-stretch pl-4 tracking-widest">
                <h5 class="text-text-light-7-1-plus font-semibold mb-1 break-words">
                  {{ props.data.partner.name }}
                </h5>
                <p v-if="props.data.partner.websiteUrl" class="text-caption text-text-light-7-1 uppercase break-all">
                  {{ props.data.partner.websiteUrl }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="props.data?.pricing !== ''" class="mb-7">
            <h4 class="mb-6">{{ t("products.benefitsText") }}</h4>
            <h6 id="custom-list-style" class="text-text-light-3-2" v-html="props.data?.pricing"></h6>
          </div>
          <div v-if="props.data?.partner && relatedDirectoryProducts">
            <recommended-products
              :data="relatedDirectoryProducts"
              :partner-name="props.data?.partner?.name"
              :partner-product-logo="props.partnerProductLogo"
            ></recommended-products>
          </div>
        </div>
      </div>
    </div>
  </app-modal>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";

import { usePartnerStore } from "@/stores/directories";
import { useScopeProductStore } from "@/stores/scope-product";

import closeSmallIcon from "@/assets/images/icons/navigation/close-small.svg?url";
import defaultHeaderImage from "@/assets/images/icons/product/default-header-image.png?url";
import logoPlaceholder from "@/assets/images/logos/logo-placeholder-transparent.svg";

import EnquiryAdd from "@/components/brand/icons/EnquiryAdd.vue";
import EnquiryCheck from "@/components/brand/icons/EnquiryCheck.vue";

import AppModal from "@/components/ui/modals/AppModal.vue";
import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppButtonIcon from "@/components/ui/buttons/AppButtonIcon.vue";
import RecommendedProducts from "@/components/recommended-products/RecommendedProducts.vue";

import { convertToLowercaseWithDashes } from "@/common/utils/string-utils";
import { openWebsiteLink } from "@/common/utils/open-website-util";

import { ButtonColours, ButtonSize } from "@/common/constants/buttons";
import { ScopeProductItem } from "@/stores/scope-product/types";

import i18n from "@/plugins/vue-i18n";
import { ScopeProductBody } from "@/api/scope-product/types";
import { Product } from "@/stores/product/types";

const { t } = i18n.global;

const partnerStore = usePartnerStore();
const scopeProductStore = useScopeProductStore();

const { partner } = storeToRefs(partnerStore);
const { scopeProducts } = storeToRefs(scopeProductStore);

const props = defineProps({
  data: {
    type: Object as () => Product,
    required: true,
  },
  isDirectoryModal: {
    type: Boolean,
    default: false,
  },
  partnerProductLogo: {
    type: String,
    default: "",
  },
});

const appModal = ref();

const relatedDirectoryProducts = ref();

const productImage = computed(() => (props.data?.headerImageUrl === "" ? defaultHeaderImage : props.data?.headerImageUrl));

const productIsEnquired = computed(
  () => scopeProducts.value?.scopeProducts?.some((scopeProduct: ScopeProductItem) => scopeProduct.product.id === props.data?.id) || false,
);

async function openProductModal() {
  appModal.value.openModal();
  if (props.data?.partner?.id) {
    if (!props.isDirectoryModal) await partnerStore.getPartner(props.data.partner.id);
    relatedDirectoryProducts.value = partner.value.relatedProducts;
  }
}

function closeProductModal() {
  appModal.value.closeModal();
}

async function onEnquireClick() {
  if (productIsEnquired.value) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const scopeProductItem = scopeProducts.value?.scopeProducts.find((scopeProduct: ScopeProductItem) => scopeProduct.product.id === props.data?.id);

    if (scopeProductItem) {
      await scopeProductStore.deleteScopeProduct(scopeProductItem.id);
    }
  } else {
    await scopeProductStore.saveScopeProduct({
      scopeId: scopeProducts.value.id !== 0 ? scopeProducts.value.id : null,
      productIds: [props.data?.id],
    } as ScopeProductBody);
  }
  await scopeProductStore.getScopeProducts();
  window.dataLayer?.push({
    event: "marketplace_button_enquire_click",
    event_category: "enquiry",
    product_name: props.data?.name,
  });
}

defineExpose({
  openProductModal,
  closeProductModal,
});
</script>

<style>
#custom-list-style li {
  background: url("src/assets/images/icons/list-item-check.svg") no-repeat;
  list-style: none;
  padding-left: 3em;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}
</style>
