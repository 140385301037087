<template>
  <div class="min-h-full" :class="[mobileMenuIsActive ? 'fixed' : '']">
    <main>
      <div class="relative flex flex-col md:flex-row">
        <app-banner :header-text="customBannerName as string" :profile-toolbar="bannerObject?.showProfileDropdown"></app-banner>
        <app-navigation @updateMenuState="setMobileMenuState"></app-navigation>
        <div class="w-full min-h-screen pt-28 pb-5 px-2 sm:px-10 md:pt-30 lg:pl-72">
          <slot></slot>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useFlowStore } from "@/stores/flow";
import { useVosApplicationStore } from "@/stores/vos-application";

import AppNavigation from "@/components/ui/AppNavigation.vue";

import AppBanner from "@/components/ui/AppBanner.vue";
import { useRoute } from "vue-router";
import { BannerObject } from "@/stores/app/types";

const flowStore = useFlowStore();
const { flow } = storeToRefs(flowStore);

const vosApplicationStore = useVosApplicationStore();
const { application } = storeToRefs(vosApplicationStore);

const route = useRoute();

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const bannerObject = computed<BannerObject>(() => route.meta?.banner as BannerObject);

const mobileMenuIsActive = ref(false);

const customBannerName = computed(() => {
  if (route.name === "my-proposal") {
    return route.params.proposalName;
  } else if (route.name === "generic-embedded") {
    return application.value?.name || "";
  } else if (route.name === "generic-start" || route.name === "generic-form" || route.name === "generic-end") {
    return flow.value?.name || "";
  }

  return bannerObject.value?.headerText;
});

const setMobileMenuState = (value: boolean): void => {
  mobileMenuIsActive.value = value;
};
</script>
