import advisors from "@/translations/en/components/advisors";
import contactSales from "@/translations/en/components/contact-sales";
import dashboard from "@/translations/en/components/dashboard";
import directory from "@/translations/en/components/directory";
import enquire from "@/translations/en/components/enquire";
import genericFlow from "@/translations/en/components/generic-flow";
import maintenance from "@/translations/en/components/maintenance";
import marketplace from "@/translations/en/components/marketplace";
import products from "@/translations/en/components/products";
import profileDropdown from "@/translations/en/components/profile-dropdown";
import proposals from "@/translations/en/components/proposals";
import recommendedProducts from "@/translations/en/components/recommended-products";
import resources from "@/translations/en/components/resources";
import routes from "@/translations/en/routes";
import saasTools from "@/translations/en/components/saasTools";
import ventureProfile from "@/translations/en/components/venture-profile";
import ventures from "@/translations/en/components/ventures";

export default {
  advisors,
  contactSales,
  dashboard,
  directory,
  enquire,
  error: {
    actions: {
      contactSupport: "Contact support",
      goToDashboard: "Navigate to Dashboard",
      refresh: "Refresh",
    },
    badRequest: {
      description: "The request could not be understood by the server due to malformed syntax.",
      subtitle: "Bad request",
      title: "400",
    },
    fileSize: "File cannot be bigger than 2MB",
    forbidden: {
      description: "You do not have permission to perform the requested action. Please contact support.",
      subtitle: "Forbidden",
      title: "403",
    },
    invalidEmail: "Please provide a valid email",
    invalidToken: {
      description: "The link you are trying to access is either invalid or has expired. Please try resending it or contact support.",
      subtitle: "Invalid or expired token",
      title: "",
    },
    notFound: {
      description: "The page you are looking for might have been removed, had its name changed or be temporarily unavailable.",
      subtitle: "This page cannot be found",
      title: "404",
    },
    pushNotification: "Failed to retrieve push notification:",
    requiredField: "This is a required field.",
    serverError: {
      description: "There appears to be some technical difficulties with our servers. Please refresh your page or try again later.",
      subtitle: "Server error",
      title: "500",
    },
    serviceUnavailable: {
      description: "The service is temporarily unavailable due to either overload or scheduled maintenance. Please try again later.",
      subtitle: "Service unavailable",
      title: "503",
    },
    unauthorized: {
      description: "You do not have permission to view this page. Please contact support.",
      subtitle: "Access denied",
      title: "401",
    },
    unknownError: {
      description: "An unknown error has occurred. We’re sorry for this. Please try refreshing your page, or contact support.",
      subtitle: "Unknown Error",
      title: "",
    },
  },
  funding: {
    title: "Fundraising Audit",
    subTitle: "Identify the key risks in whether you are fundraise ready.",
    formToast: "Your progress has been saved.",
  },
  gapAnalysis: {
    title: "Gap Analysis",
    thankYou: {
      header: "Welcome to DeltaOS!",
      description:
        "Thanks for telling us a little bit about your venture. This will enable us to personalise your experience as you explore the marketplace and community.",
      subHeader: "Let’s get started!",
      marketplace: {
        title: "Marketplace",
        description: "We have a range of recommended products lined up just for you",
      },
      community: {
        title: "Explore Community",
        description: "Join our Delta community to meet founders, investors and industry specialists",
      },
    },
  },
  auth: {
    email: "Enter your email address",
    backToTheDelta: "Back to The Delta",
  },
  general: {
    active: "Active",
    all: "All",
    amount: "Amount",
    clear: "Clear",
    close: "Close",
    comingSoon: "Coming Soon",
    contactImage: "Contact image",
    country: "Country | Countries",
    date: "Date",
    download: "Download",
    email: "Email",
    enterEmail: "Enter your email address",
    enterField: "Enter {field}",
    fieldNotFound: "{field} not found",
    filter: "Filter",
    firstName: "First Name",
    fundingStage: "Funding Stage | Funding Stages",
    getInTouch: "Get in touch",
    goToDashboard: "Go to dashboard",
    header: "Header",
    image: "Image",
    industry: "Industry | Industries",
    lastName: "Last Name",
    linkedIn: "LinkedIn",
    loading: "Loading...",
    login: "Login",
    logo: "Logo",
    logOut: "Log Out",
    name: "Name",
    next: "Next",
    nextSteps: "Next Steps",
    no: "No",
    of: "of",
    open: "Open",
    past: "Past",
    pending: "Pending",
    processing: "Processing...",
    progressSaved: "Your progress has been saved.",
    recommended: "Recommended",
    save: "Save",
    saveProgress: "Save progress",
    search: "Search",
    select: "Select | Selected",
    selectField: "Select {field}",
    setup: "Setup",
    showGuide: "Show Guide",
    signUp: "Sign Up",
    submit: "Submit",
    subtotal: "Subtotal",
    tags: "Tags",
    totalAmount: "Total Amount (VAT Excl.)",
    trySearch: "Try search for another {field}",
    updatedField: "{field} updated",
    uploadImage: "Upload image",
    url: "URL",
    venture: "Venture | Ventures",
    visitWebsite: "Visit website",
    website: "Website",
    yes: "Yes",
  },
  genericFlow,
  maintenance,
  marketplace,
  products,
  profileDropdown,
  proposals,
  recommendedProducts,
  resources,
  routes,
  saasTools,
  ventureProfile,
  ventures,
};
