export default {
  addressLine1: "address line 1",
  addressLine2: "address line 2",
  add: "Add",
  addTags: "Add Tags",
  addTagsPlaceholder: "Type a tag name and press add",
  addYourVentureDetails: "Please add your venture details below to continue",
  billing: "Billing",
  billingEmail: "billing email",
  billingName: "billing name",
  calendly: "Calendly",
  city: "City",
  companyLinkedIn: "Company LinkedIn",
  companyName: "company name",
  contactEmail: "contact email",
  contactName: "contact name",
  contactType: "Preferred contact type",
  contactTypes: {
    none: "None",
    email: "Email",
    calendly: "Calendly",
  },
  country: "Country",
  deltaNda: "Delta NDA",
  deltaPrivacyPolicy: "Delta Privacy Policy",
  describeYourVenture: "Describe your venture",
  exampleWebsite: "https://www.example.com",
  foundedDate: "Founded date",
  general: "General",
  getStarted: "To get started please tell us a little bit more about you and your venture.",
  headerImage: "Header image (Dimensions - 820 x 312px)",
  iAgreeToThe: "I agree to the",
  linkedInWebsite: "https://www.linkedin.com/",
  pitchDeck: "Pitch deck",
  registrationNumber: "registration number",
  shortDescription: "Provide a short summary",
  suburb: "suburb",
  teamSize: "Team size",
  tooltip: {
    ventureName: "If you don’t have a venture yet, simply enter your name.",
  },
  typeDescriptionHere: "Type description here",
  typeShortDescriptionHere: "Type summary here",
  uploadYourContactImage: "Upload contact image",
  uploadYourHeader: "Upload your header image",
  uploadYourLogo: "Upload your logo",
  vatNumber: "VAT number",
  ventureDetails: "Venture details",
  ventureName: "venture name",
  ventureProfile: "Venture Profile",
  website: "Website",
};
