/**
 * "Convert a string to lowercase and replace spaces with dashes."
 * @param {string} name - The name to convert.
 * @returns A function that takes a string and returns a string.
 */
export function convertToLowercaseWithDashes(name: string): string {
  return name.replace(/\s+/g, "-").toLowerCase();
}

/**
 * "If the string is less than the minimum length, return a string that says how many characters are
 * needed, otherwise return an empty string."
 * @param {string} string - The string to validate
 * @param {number} minLength - The minimum number of characters that the string must have.
 * @returns A string
 */
export function minimumCharacterValidation(string: string, minLength: number): string {
  return string.length < minLength ? `At least ${minLength} characters` : "";
}

/**
 * It takes a string, splits it into an array of words, maps over the array to get the first letter of
 * each word, joins the letters together, and returns the result in uppercase
 * @param {string} string - The string to get the initials from.
 * @returns A string of the first letter of each word in the string.
 */
export function getInitials(string: string): string {
  return string
    .split(" ")
    .map((item) => {
      return item[0];
    })
    .join("")
    .toUpperCase();
}

/**
 * It takes a string, capitalises the first letter, and returns the result
 * @param {string} string - string - This is the string that we want to capitalise.
 * @returns The first letter of the string is being capitalised and then the rest of the string is
 * being added to it.
 */
export function capitaliseFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Removes special characters from a string and replaces them with a specified value.
 * @param {string} value - The input string to remove special characters from.
 * @param {string} replaceValue - The value to replace the special characters with.
 * @returns {string} The input string with special characters replaced by the specified value.
 */
export const removeAndReplaceSpecialCharacters = (value: string, replaceValue: string) => {
  const regex = /[^a-zA-Z0-9]/g;
  return value.replace(regex, replaceValue);
};
