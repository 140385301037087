import { AxiosResponse } from "axios";
import { createAxiosRequest } from "../api-base";
import { ProductPaging } from "./types";

const resource = "products";

/**
 * Sends a GET request to the /products endpoint with paging parameters (gets a paged list of products).
 * @param {ProductPaging} ProductPaging - ProductPaging
 * @returns AxiosResponse
 */
export async function getProducts(ProductPaging: ProductPaging): Promise<AxiosResponse> {
  let ProductSubcategoryIdString = "";
  ProductPaging.ProductSubcategoryId?.map((id: number, index: number) => {
    ProductSubcategoryIdString =
      ProductSubcategoryIdString + `ProductSubCategoryIds%5B%5D=${id}${ProductPaging.ProductSubcategoryId?.length - 1 === index ? "" : "&"}`;
  });

  return await createAxiosRequest(resource, 1).get(
    `/paged?PageNo=${ProductPaging.PageNo}&PageSize=${ProductPaging.PageSize}${
      ProductPaging.ProductCategoryId ? `&ProductCategoryId=${ProductPaging.ProductCategoryId}` : ""
    }&${ProductSubcategoryIdString}&Recommended=${ProductPaging.Recommended}&SearchText=${ProductPaging.SearchText}`,
  );
}

/**
 * Returns product information for provided id
 * @param {number} id - number - the id of the product you want to get
 * @returns An AxiosResponse object
 */
export async function getProduct(id: number): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).get(`/${id}`);
}
