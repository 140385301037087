import { AxiosResponse } from "axios";
import { createAxiosRequest } from "../api-base";
import { ScopeProductBody } from "@/api/scope-product/types";

const resource = "scope-products";

/**
 * "This function will return a list of products for the venture's scope."
 * @returns AxiosResponse
 */
export async function getScopeProducts(): Promise<AxiosResponse> {
  return await createAxiosRequest(resource, 1).get("/");
}

/**
 * It takes a ScopeProductBody object and save product to a scope
 * @param {ScopeProductBody} scopeProduct - ScopeProductBody
 * @returns AxiosResponse
 */
export async function saveScopeProduct(scopeProduct: ScopeProductBody): Promise<AxiosResponse> {
  return await createAxiosRequest(resource, 1).post("/", scopeProduct);
}

/**
 * It deletes a product from the database
 * @param {number} productId - number - The ids of the product to delete
 * @returns AxiosResponse
 */
export async function deleteScopeProduct(productId: number): Promise<AxiosResponse> {
  return await createAxiosRequest(resource, 1).delete(`/${productId}`);
}
