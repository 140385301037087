<template>
  <app-loader />
  <component :is="currentLayout" v-if="isRouterLoaded">
    <router-view />
  </component>
</template>

<script setup lang="ts">
import { computed, DefineComponent, onBeforeMount, onMounted, ref } from "vue";

import AppLoader from "@/components/utilities/AppLoader.vue";

import bannerLayout from "@/layouts/BannerLayout.vue";
import defaultLayout from "@/layouts/DefaultLayout.vue";
import emptyLayout from "@/layouts/EmptyLayout.vue";
import errorLayout from "@/layouts/ErrorLayout.vue";
import loginLayout from "@/layouts/LoginLayout.vue";
import logoOnlyLayout from "@/layouts/LogoOnlyLayout.vue";
import onboardingLayout from "@/layouts/OnboardingLayout.vue";

import { getUserToken, onMessageListener } from "@/plugins/firebase";
import { showError, showFirebaseInfo } from "@/common/utils/notification-utils";
import AppToastComponent from "@/components/ui/AppToastComponent.vue";
import { checkMandatoryRouteLogic } from "@/common/utils/routing/router-utils";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import i18n from "@/plugins/vue-i18n";

const authStore = useAuthStore();
const { authenticationData } = storeToRefs(authStore);

const { t } = i18n.global;

const firebaseUserToken = ref("");
const route = useRoute();

const layouts: Record<string, DefineComponent> = {
  default: defaultLayout as unknown as DefineComponent,
  banner: bannerLayout as unknown as DefineComponent,
  empty: emptyLayout as unknown as DefineComponent,
  error: errorLayout as unknown as DefineComponent,
  login: loginLayout as unknown as DefineComponent,
  logoOnly: logoOnlyLayout as unknown as DefineComponent,
  onboard: onboardingLayout as unknown as DefineComponent,
};

const currentLayout = computed(() => {
  return layouts[route.meta?.layout as string] || defaultLayout;
});

const isRouterLoaded = computed(() => {
  return !!route.name;
});

onMessageListener()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  .then((payload: any) => {
    if (firebaseUserToken.value !== "") {
      const toastContent = {
        component: AppToastComponent,
        props: {
          title: payload.notification.title,
          description: payload.notification.body,
        },
      };

      showFirebaseInfo(toastContent);
    }
  })
  .catch((err) => {
    showError(`${t(`error.pushNotification`)} ${err}`);
  });

onBeforeMount(() => {
  checkMandatoryRouteLogic(window.location.pathname, authenticationData.value);
});

onMounted(() => {
  if (firebaseUserToken.value === "") {
    firebaseUserToken.value = getUserToken();
  }
});
</script>

<style>
body {
  background-color: #f4f6fa;
}

/*magic script was blocking cookie script popup*/
.magic-iframe {
  z-index: 1 !important;
}
</style>
