import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DashboardRoutes from "@/router/dashboard.routes";
import GenericFlowRoutes from "@/router/generic-flow.routes";
import MarketplaceRoutes from "@/router/marketplace.routes";
import OnboardingRoutes from "@/router/onboarding.routes";
import DirectoriesRoutes from "@/router/directories.routes";
import PoliciesRoutes from "@/router/policies.routes";
import ProposalsRoutes from "@/router/proposals.routes";
import VentureProfileRoutes from "@/router/venture-profile.routes";
import ErrorType from "@/common/constants/error";
import { useProfileStore } from "@/stores/profile";
import SaasToolsRoutes from "@/router/saas-tools.routes";
import ResourcesRoutes from "@/router/resources.routes";
import AdvisorsRoutes from "@/router/advisors.routes";
import VenturesRoutes from "@/router/ventures.routes";

// Routes to sections of the app should be kept in separate files
// eg: auth.routes.ts

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: { name: "welcome" },
  },
  // Temporary redirect for anyone that has a bookmark to the old login page.
  {
    name: "login",
    path: "/login",
    redirect: { name: "welcome" },
  },
  {
    name: "generic-embedded",
    path: "/app/:id",
    component: () => import("@/views/generic-embedded/GenericEmbedded.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      banner: {
        showProfileDropdown: true,
        headerText: "",
      },
      navigation: {
        showNavigationItems: true,
      },
      layout: "default",
    },
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: () => import("@/views/MaintenanceView.vue"),
    meta: {
      title: "",
      layout: "logoOnly",
    },
  },
  {
    path: "/error/:errorType",
    name: "error",
    component: () => import(/* webpackChunkName: "error" */ "@/views/error/ErrorView.vue"),
    props: true,
    meta: {
      title: "Unknown Error",
      layout: "error",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import(/* webpackChunkName: "notfound" */ "@/views/error/ErrorView.vue"),
    props: () => ({ errorType: ErrorType.NOT_FOUND }),
    meta: {
      title: "Page not found",
      layout: "error",
    },
  },
  ...AdvisorsRoutes,
  ...DashboardRoutes,
  ...GenericFlowRoutes,
  ...MarketplaceRoutes,
  ...OnboardingRoutes,
  ...DirectoriesRoutes,
  ...PoliciesRoutes,
  ...ProposalsRoutes,
  ...ResourcesRoutes,
  ...SaasToolsRoutes,
  ...VentureProfileRoutes,
  ...VenturesRoutes,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

const isRedirectableRoute = (routeName: string) => {
  return !(
    routeName === "dashboard" ||
    routeName === "onboarding" ||
    routeName === "welcome" ||
    routeName === "onboarding-venture-profile" ||
    routeName === "gap-analysis" ||
    routeName === "thank-you"
  );
};

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const loggedIn = JSON.parse(localStorage.getItem("authData"));
  const redirectUrl = localStorage.getItem("redirect") ?? "";
  const profileStore = useProfileStore();
  const isMaintenanceMode = import.meta.env.VITE_MAINTENANCE_MODE === "true";

  if (!loggedIn && redirectUrl === "" && isRedirectableRoute(to.name as string)) {
    const previousPath = to.path;
    localStorage.setItem("redirect", previousPath);
  }

  if (to.params.errorType === ErrorType.UNAUTHORIZED) {
    profileStore.logout();
    return next({ name: "welcome" });
  } else if (isMaintenanceMode && to.name !== "maintenance") {
    return next({ name: "maintenance" });
  } else if (!isMaintenanceMode && to.name === "maintenance") {
    return next({ name: "welcome" });
  } else if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    return next({ name: "welcome" });
  } else if (to.name === "login" && from.name !== "welcome" && !loggedIn) {
    return next({ name: "welcome" });
  } else if ((to.name === "login" || to.name === "welcome") && loggedIn?.ventureProfileDone === true && loggedIn?.ventureOnboarded === true) {
    return next({ name: "dashboard" });
  } else if ((to.name === "login" || to.name === "welcome") && loggedIn?.ventureProfileDone === false && loggedIn?.ventureOnboarded === false) {
    return next({ name: "onboarding" });
  }

  return next();
});

export default router;
