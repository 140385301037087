import { AxiosResponse } from "axios";
import { createAxiosRequest } from "../api-base";
import { PayloadData } from "./types";

const resource = "authentication";

/**
 * It sends a POST request to the /authentication/magic endpoint of the auth resource with the payload data
 * @param {PayloadData} payload - PayloadData - This is the payload that will be sent to the server.
 * @returns AxiosResponse
 */
export async function authenticateMagicLogin(payload: PayloadData): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1, "json", false).post("/magic", payload);
}
