import { RouteRecordRaw } from "vue-router";
import i18n from "@/plugins/vue-i18n";

export default [
  {
    path: "/venture-profile",
    name: "venture-profile",
    component: () => import("../views/venture-profile/VentureProfile.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
      banner: {
        showProfileDropdown: true,
        headerText: i18n.global.t(`routes.ventureProfilePageTitle`),
      },
      navigation: {
        showNavigationItems: true,
      },
    },
  },
] as RouteRecordRaw[];
