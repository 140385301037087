import { RouteRecordRaw } from "vue-router";
import i18n from "@/plugins/vue-i18n";

export default [
  {
    path: "/policies",
    name: "policies",
    component: () => import("@/views/onboarding/OnboardingPage.vue"),
    children: [
      {
        path: "",
        name: "policies-parent",
        redirect: { name: "dashboard" },
      },
      {
        path: "privacy-policy",
        name: "privacy-policy",
        component: () => import("@/views/policies/PrivacyPolicy.vue"),
        meta: {
          requiresAuth: false,
          banner: {
            headerText: i18n.global.t(`routes.privacyPolicyPageTitle`),
          },
          navigation: {
            showNavigationItems: false,
          },
        },
      },
      {
        path: "non-disclosure-agreement",
        name: "non-disclosure-agreement",
        component: () => import("@/views/policies/NonDisclosureAgreement.vue"),
        meta: {
          requiresAuth: false,
          banner: {
            headerText: i18n.global.t(`routes.ndaPageTitle`),
          },
          navigation: {
            showNavigationItems: false,
          },
        },
      },
    ],
  },
] as RouteRecordRaw[];
