import { defineStore } from "pinia";
import { createActionHelpers } from "@/stores/helpers/global-loader";
import { deleteScopeProduct, getScopeProducts, saveScopeProduct } from "@/api/scope-product/scope-product-api";
import { ScopeProduct, ScopeProducts, ScopeProductState } from "@/stores/scope-product/types";
import { ScopeProductBody } from "@/api/scope-product/types";

const { startLoading } = createActionHelpers();
const loaderName = "scope-product";

export const useScopeProductStore = defineStore(loaderName, {
  state: (): ScopeProductState => ({
    scopeProduct: {} as ScopeProduct,
    scopeProducts: {} as ScopeProducts,
  }),
  actions: {
    async getScopeProducts() {
      const response = await startLoading(loaderName, getScopeProducts());
      this.scopeProducts = response.data.data;
    },
    async saveScopeProduct(scopeProductBody: ScopeProductBody) {
      const response = await startLoading(loaderName, saveScopeProduct(scopeProductBody));
      this.scopeProduct = response.data.data;
    },
    async deleteScopeProduct(productId: number) {
      await startLoading(loaderName, deleteScopeProduct(productId));
    },
  },
});
