export enum ButtonSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum ButtonColours {
  WHITE = "white",
  WHITE_WITH_BORDER = "whiteWithBorder",
  GRAY = "gray",
  BLACK = "black",
  GREEN = "green",
  DISABLED = "disabled",
  NONE = "none",
  NONE_WITH_BORDER = "noneWithBorder",
}
