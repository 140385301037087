import { defineStore } from "pinia";
import { PartnerDetail, PartnerState } from "./types";
import { getPartners, getPartner } from "@/api/directory/directory-api";

export const usePartnerStore = defineStore("partners", {
  state: (): PartnerState => ({
    partners: [],
    partner: {} as PartnerDetail,
  }),
  actions: {
    async getPartners(SearchText: string) {
      const response = await getPartners(SearchText);
      this.partners = response.data.data;
    },
    async getPartner(id: number) {
      this.partner = {} as PartnerDetail;
      const response = await getPartner(id);
      this.partner = response.data.data;
    },
  },
});
