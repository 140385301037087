<template>
  <div v-if="!showThankYou" class="h-screen flex flex-col justify-between">
    <div class="p-6 pb-9 bg-background-delta">
      <app-button-icon
        data-cy="button-close-enquire-modal"
        :button-size="ButtonSize.LARGE"
        :button-colour="ButtonColours.WHITE"
        :icon-image="closeIcon"
        @click="closeModal"
      ></app-button-icon>
      <h3 class="text-center mt-[-13px] font-medium">{{ t("enquire.yourEnquiry") }}</h3>
    </div>

    <div class="h-full flex flex-col items-center px-6 pb-6 overflow-auto">
      <div v-if="scopeProducts?.scopeProducts?.length > 0" class="w-full grid grid-cols-1 gap-2.5">
        <enquire-product-card v-for="product in scopeProducts?.scopeProducts" :key="product" :data="product"></enquire-product-card>
      </div>
      <h6 v-else>{{ t("enquire.emptyMessage") }}</h6>
    </div>

    <div class="px-2 pt-4 pb-10 md:px-20 md:pt-35px md:pb-35px bg-background-light-light border-t-background-light-light-2">
      <h6 class="mb-5 text-center">
        {{ t("enquire.enquireNowMessage") }}
      </h6>
      <app-button
        id="google-button-enquire-now"
        data-cy="button-enquire-now"
        name="enquire-now"
        :text="t('enquire.enquireNow')"
        size="medium"
        :color="scopeProducts?.scopeProducts?.length <= 0 ? 'gray' : 'black'"
        :text-color="`white`"
        :style="`roundedFull`"
        :is-disabled="scopeProducts?.scopeProducts?.length <= 0"
        class="m-auto"
        @click="enquireScope"
      />
    </div>
  </div>
  <enquire-thank-you v-else @close-modal="closeModal"></enquire-thank-you>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";

import { useScopeStore } from "@/stores/scope";
import { useScopeProductStore } from "@/stores/scope-product";

import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppButtonIcon from "@/components/ui/buttons/AppButtonIcon.vue";
import EnquireThankYou from "@/components/enquire/EnquireThankYou.vue";

import EnquireProductCard from "@/components/enquire/EnquireProductCard.vue";

import { ButtonColours, ButtonSize } from "@/common/constants/buttons";

import closeIcon from "@/assets/images/icons/close.svg?url";

import i18n from "@/plugins/vue-i18n";

const { t } = i18n.global;

const emits = defineEmits(["closeModal", "update:showThankYou"]);

const scopeStore = useScopeStore();
const scopeProductStore = useScopeProductStore();
const { scopeProducts } = storeToRefs(scopeProductStore);

const showThankYou = ref(false);

function closeModal() {
  emits("closeModal");
}

async function enquireScope() {
  await scopeStore.enquireScope(scopeProducts.value.id);
  showThankYou.value = true;
  await scopeProductStore.getScopeProducts();
}
</script>
