import { defineStore } from "pinia";
import { createActionHelpers } from "@/stores/helpers/global-loader";
import { Flow, FlowState } from "./types";
import { getDefaultFlow, getFlow } from "@/api/flow/flow";
const { startLoading } = createActionHelpers();

export const useFlowStore = defineStore("flow", {
  state: (): FlowState => ({
    flow: {} as Flow,
    currentFlowOrderNumber: 0,
  }),
  actions: {
    async getFlow(flowId: number) {
      const response = await startLoading("flow", getFlow(flowId));
      this.flow = response.data.data;
    },
    async getDefaultFlow() {
      const response = await startLoading("flow", getDefaultFlow());
      this.flow = response.data.data;
    },
    async setCurrentFlowOrderNumber(orderNumber: number) {
      this.currentFlowOrderNumber = orderNumber;
    },
    async setDefaultFlowData() {
      this.flow = {} as Flow;
    },
  },
});
