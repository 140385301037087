import { RouteRecordRaw } from "vue-router";
import i18n from "@/plugins/vue-i18n";

export default [
  {
    path: "/marketplace",
    name: "marketplace",
    component: () => import("@/views/marketplace/MarketplaceView.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      banner: {
        showProfileDropdown: true,
        headerText: i18n.global.t(`routes.marketplacePageTitle`),
      },
      navigation: {
        showNavigationItems: true,
      },
      layout: "banner",
    },
  },
] as RouteRecordRaw[];
