import { createApp } from "vue";
import { Integrations } from "@sentry/tracing";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import configs from "./configs";
import i18n from "./plugins/vue-i18n";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import VueTippy from "vue-tippy";
import Toast, { POSITION } from "vue-toastification";
import * as FullStory from "@fullstory/browser";
import Datepicker from "@vuepic/vue-datepicker";
import { createGtm } from "@gtm-support/vue-gtm";
import Vue3Sanitize from "vue-3-sanitize";

import "@/assets/index.css";
import "tippy.js/dist/tippy.css";
import "vue-toastification/dist/index.css";
import "@vuepic/vue-datepicker/dist/main.css";

if (import.meta.env.VITE_FULLSTORY_ORG_ID) {
  FullStory.init({ orgId: import.meta.env.VITE_FULLSTORY_ORG_ID });
}

const app = createApp(App);
app.config.globalProperties.$FullStory = FullStory;

// Sentry
if (configs.sentry.dsn) {
  Sentry.init({
    app: app,
    dsn: configs.sentry.dsn,
    environment: configs.sentry.env,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: configs.sentry.tracesSampleRate,
    logErrors: true,
  });
}

app.use(createGtm({ id: import.meta.env.VITE_APP_GA_MEASUREMENT_ID }));
app.use(createPinia());
app.use(i18n);
app.use(router);
app.use(VueApexCharts);
app.use(VueTippy, {
  directive: "tippy",
  defaultProps: { placement: "bottom", theme: "venture" },
});
app.use(Toast, { position: POSITION.TOP_CENTER, pauseOnFocusLoss: false });
app.use(Vue3Sanitize);
app.component("DatePicker", Datepicker);
app.mount("#app");

export default app;
