import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/flow/:flowId",
    name: "generic-flow",
    component: () => import("@/views/generic-flow/GenericPage.vue"),
    children: [
      {
        path: "start",
        name: "generic-start",
        component: () => import("@/views/generic-flow/StartPage.vue"),
      },
      {
        path: "form",
        name: "generic-form",
        component: () => import("@/views/generic-flow/FormPage.vue"),
      },
      {
        path: "end",
        name: "generic-end",
        component: () => import("@/views/generic-flow/EndPage.vue"),
      },
      {
        path: "report",
        name: "generic-report",
        component: () => import("@/views/generic-flow/ReportPage.vue"),
      },
    ],
    props: true,
    meta: {
      requiresAuth: true,
      banner: {
        showProfileDropdown: true,
        headerText: "",
      },
      navigation: {
        showNavigationItems: true,
      },
    },
  },
] as RouteRecordRaw[];
