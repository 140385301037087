<template>
  <div v-if="venture" class="absolute top-4 right-0 sm:right-5 z-20 bg-background-light-light flex justify-end">
    <app-button
      id="request-call-button"
      data-cy="button-request-call"
      name="requestCall"
      :text="t('profileDropdown.requestACall')"
      size="small"
      :color="`black`"
      :text-color="`white`"
      :style="`roundedFull`"
      class="mt-1.5 mr-4 sm:mr-9 hidden md:block"
      @click="openCalendlylink"
    />
    <div :class="[!venture.profileImageUrl ? 'mt-2' : '']">
      <img
        v-if="venture.profileImageUrl"
        :src="venture.profileImageUrl"
        class="rounded-full bg-background-light-light min-w-[40px] w-11 h-11 inline border border-delta-dark-1"
        alt="user-logo"
      />
      <div v-else class="rounded-full bg-background-light-light min-w-[40px] p-3 w-11 h-11 inline border border-delta-dark-1 text-body3 font-medium">
        {{ initials }}
      </div>
      <p class="p-2 pr-1 ml-2 font-medium text-body3 truncate inline hidden sm:inline">
        {{ venture.name }}
      </p>
    </div>

    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton class="mt-1 mr-2">
          <app-button-icon
            id="google-profile-dropdown"
            data-cy="profile-dropdown"
            :icon-image="!clicked ? chevronDownIcon : chevronUpIcon"
            :button-colour="ButtonColours.WHITE"
            :button-size="ButtonSize.SMALL"
            class="m-auto"
            @click="clicked = !clicked"
          >
          </app-button-icon>
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div class="py-1">
            <MenuItem class="sm:hidden">
              <p class="block w-full text-left px-4 py-2 text-text-light-7-1-plus text-sm">
                {{ venture.name }}
              </p>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a
                id="google-profile-dropdown-imprint"
                data-cy="profile-dropdown-imprint"
                :href="imprintLink"
                target="_blank"
                :class="[active ? 'bg-gray-100 text-text-light-7-1' : 'text-text-light-4.5-1', 'block w-full text-left px-4 py-2 text-sm']"
              >
                {{ t("profileDropdown.imprint") }}
              </a>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <router-link
                id="google-profile-dropdown-privacy-policy"
                data-cy="profile-dropdown-privacy-policy"
                :to="{ name: 'privacy-policy' }"
                target="_blank"
                :class="[active ? 'bg-gray-100 text-text-light-7-1' : 'text-text-light-4.5-1', 'block w-full text-left px-4 py-2 text-sm']"
              >
                {{ t("profileDropdown.privacyPolicy") }}
              </router-link>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <router-link
                id="google-profile-non-disclosure-agreement"
                data-cy="profile-non-disclosure-agreement"
                :to="{ name: 'non-disclosure-agreement' }"
                target="_blank"
                :class="[active ? 'bg-gray-100 text-text-light-7-1' : 'text-text-light-4.5-1', 'block w-full text-left px-4 py-2 text-sm']"
              >
                {{ t("profileDropdown.ndaAgreement") }}
              </router-link>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <router-link id="google-profile-dropdown-logout" data-cy="profile-dropdown-logout" :to="{ name: 'login' }" custom>
                <span
                  role="link"
                  :class="[
                    active ? 'bg-gray-100 text-text-light-7-1' : 'text-text-light-4.5-1',
                    'block w-full text-left px-4 py-2 text-sm cursor-pointer hover:bg-gray-100',
                  ]"
                  @click="logout"
                  @keypress.enter="logout"
                >
                  {{ t("general.logOut") }}
                </span>
              </router-link>
            </MenuItem>
            <MenuItem>
              <p class="text-center text-text-light-3-1 text-xs mt-4 mb-2 border-t pt-1">
                {{ version }}
              </p>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>

    <div class="flex items-center relative mr-2">
      <div class="border-l border-background-delta-dark-1 w-px h-26px mr-17px"></div>
      <app-button-icon
        id="google-open-enquiries"
        v-tippy
        data-cy="button-open-enquiries"
        :icon-image="enquiriesIconBlack"
        :button-colour="ButtonColours.GRAY"
        :button-size="ButtonSize.LARGE"
        :content="t('profileDropdown.viewEnquiries')"
        class="rounded-full"
        @click="openEnquireModal"
      >
      </app-button-icon>
      <div
        v-if="scopeProducts?.scopeProducts?.length > 0"
        class="absolute h-2.5 w-2.5 bg-semantic-light-success rounded-full right-[-3px] top-0 drop-shadow-notification-green animate-pulse"
      ></div>
    </div>
  </div>
  <app-enquire-modal ref="appEnquireModal"></app-enquire-modal>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";

import { useAuthStore } from "@/stores/auth";
import { useScopeProductStore } from "@/stores/scope-product";
import { useVentureStore } from "@/stores/venture";

import AppEnquireModal from "@/components/enquire/EnquireModal.vue";
import AppButtonIcon from "@/components/ui/buttons/AppButtonIcon.vue";
import AppButton from "@/components/ui/buttons/AppButton.vue";

import { getInitials } from "@/common/utils/string-utils";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

import { ButtonColours, ButtonSize } from "@/common/constants/buttons";

import enquiriesIconBlack from "@/assets/images/icons/enquiries-black.svg";
import chevronDownIcon from "@/assets/images/icons/chevron-down-black.svg?url";
import chevronUpIcon from "@/assets/images/icons/chevron-up-black.svg?url";
import { useProfileStore } from "@/stores/profile";

import i18n from "@/plugins/vue-i18n";

const { t } = i18n.global;

// eslint-disable-next-line no-undef
const version = __APP_VERSION__;

const authStore = useAuthStore();
const profileStore = useProfileStore();
const scopeProductStore = useScopeProductStore();
const ventureStore = useVentureStore();

const { authenticationData } = storeToRefs(authStore);
const { scopeProducts } = storeToRefs(scopeProductStore);
const { venture } = storeToRefs(ventureStore);

const imprintLink = ref("https://www.thedelta.io/imprint");

const initials = ref();
const clicked = ref(false);

const appEnquireModal = ref();

const openCalendlylink = async () => {
  window.open(import.meta.env.VITE_APP_SALES_DEFAULT_CALENDLY, "Sales Person Calendly", "noopener");
};

const logout = () => {
  profileStore.logout();
};

const openEnquireModal = () => {
  appEnquireModal.value.openEnquireModal();
};

onMounted(async () => {
  if (authenticationData.value?.ventureId && !venture.value) {
    await ventureStore.getVenture();
  }

  if (venture.value) initials.value = getInitials(venture.value?.name);

  await scopeProductStore.getScopeProducts();
});
</script>
