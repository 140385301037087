export default {
  buttonText: {
    email: "Send an email",
    call: "Request a call",
  },
  connectWith: "Connect with {person}",
  emailMessage: "{person} from {venture} has sent you a message: \n \n{message} \n\n{extraInfo}",
  emailSubject: "Message from DeltaOS",
  getInTouch: "Get in touch",
  getInTouchWith: "If you'd like to get in touch with {directoryName} reach out to {salesPersonName} below.",
  message: "Message",
  salesPersonName: "Anna Insam",
  sendAMessage: "Send a message to {person} and they will be in touch.",
  sendMessage: "Send Message",
  moreDetails: "More details about this venture:",
  sendEmailError: "It looks like something went wrong while sending your message. Please contact support, and try again later.",
};
