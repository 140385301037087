import { AxiosResponse } from "axios";
import { createAxiosRequest } from "../api-base";

const resource = "applications";

/**
 * Fetches all the dynamic applications that have been set up in Retool
 * @returns An AxiosResponse object
 */
export async function getVosApplications(): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).get("/");
}
