import { initializeApp, FirebaseError } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { showError } from "@/common/utils/notification-utils";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

const browserSupported = await isSupported();

const firebaseApp = browserSupported ? initializeApp(firebaseConfig) : null;
const messaging = browserSupported && firebaseApp ? getMessaging(firebaseApp) : null;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
/**
 * It returns a promise that resolves to a boolean value that indicates whether the user has granted
 * permission to receive notifications
 * @returns A boolean value.
 */
export const getUserToken = (): any => {
  if (browserSupported && messaging) {
    return getToken(messaging, { vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY })
      .then((currentToken) => {
        return !!currentToken;
      })
      .catch((err: FirebaseError) => {
        if (err.code !== "messaging/permission-blocked") {
          showError(`An error occurred while retrieving token: ${err}`);
        }
      });
  } else {
    return "";
  }
};

/**
 * It returns a promise that resolves when the onMessage listener is triggered
 */
export const onMessageListener = (): any =>
  new Promise((resolve) => {
    if (browserSupported && messaging) {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    }
  });
