import * as Sentry from "@sentry/vue";
import { AxiosResponse } from "axios";

enum SeverityLevel {
  FATAL = "fatal",
  ERROR = "error",
  WARNING = "warning",
  LOG = "log",
  INFO = "info",
  DEBUG = "debug",
}

export interface ApiErrorInfo {
  error?: string;
  response?: AxiosResponse;
}

export interface ErrorDetails {
  dialog?: boolean;
  component?: string;
  breadcrumbs?: Sentry.Breadcrumb[];
  apiError?: ApiErrorInfo;
  level?: SeverityLevel;
  fn?: string;
}

const DEFAULT = {
  dialog: false,
  breadcrumbs: [] as Sentry.Breadcrumb[],
  level: SeverityLevel.INFO,
};

interface ReturnType {
  captureError: (error: Error, details: ErrorDetails) => void;
  captureApiError: (apiError: ApiErrorInfo) => void;
}

const useSentry = (): ReturnType => {
  const userId = ""; // T O D O: set current userId eg: store.getters["authModule/userProfileId"];

  /**
   * Captures Sentry exception with added details
   * @param error exception sent to Sentry
   * @param details extra details to send with exception
   * @example
   * details
   * {
   *  dialog: boolean = false
   *  component: string
   *  breadcrumbs: Sentry.Breadcrumb[] = []
   *  httpCode: number | null
   *  level: Sentry.Severity = Sentry.Severity.Info
   *  fn: string
   * }
   */
  function captureError(error: Error, details: ErrorDetails = DEFAULT): void {
    const { width, height } = window.screen;
    const { dialog, component, breadcrumbs, apiError, level, fn } = {
      ...DEFAULT,
      ...details,
    };

    const tags = {
      resolution: `${width} x ${height}`,
      component,
      fn,
    };

    Sentry.captureException(error, (scope) => {
      Object.entries(tags).forEach(([key, value]) => {
        if (value) scope.setTag(key, value);
      });

      if (apiError) scope.setExtra("API Error", apiError);

      scope.setLevel(level);

      breadcrumbs.forEach((bc) => scope.addBreadcrumb(bc));

      // will trigger Sentry error dialog
      if (dialog) scope.setExtra("Dialog", true);

      // set user details
      scope.setUser({
        id: userId,
      });

      return scope;
    });
  }

  /**
   * It captures an error and sends it to Sentry.
   * @param {ApiErrorInfo} apiError - ApiErrorInfo
   */
  function captureApiError(apiError: ApiErrorInfo) {
    captureError(new Error("API Error"), {
      apiError,
      level: SeverityLevel.ERROR,
    });
  }

  return {
    captureError,
    captureApiError,
  };
};

export default useSentry;
