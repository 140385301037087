import { defineStore } from "pinia";
import { createActionHelpers } from "@/stores/helpers/global-loader";
import { acceptScope, enquireScope, getScope, getScopes, downloadScopeOfWorkPdf } from "@/api/scope/scope-api";
import { ScopeState, Scope } from "@/stores/scope/types";
import { ScopeAcceptBody } from "@/api/scope/types";
const { startLoading } = createActionHelpers();

const loaderName = "scope";

export const useScopeStore = defineStore(loaderName, {
  state: (): ScopeState => ({
    scopes: [],
    scope: {} as Scope,
    scopePdf: "",
  }),
  actions: {
    async enquireScope(scopeId: number) {
      await startLoading(loaderName, enquireScope(scopeId));
    },
    async getScopes() {
      const response = await startLoading(loaderName, getScopes());
      this.scopes = response.data.data;
    },
    async getScope(id: number) {
      this.scope = {} as Scope;
      const response = await startLoading(loaderName, getScope(id));
      this.scope = response.data.data;
    },
    async acceptScope(scopeAcceptBody: ScopeAcceptBody) {
      await startLoading(loaderName, acceptScope(scopeAcceptBody));
    },
    async downloadScopeOfWorkPdf(id: number) {
      this.scopePdf = "";
      const response = await startLoading(loaderName, downloadScopeOfWorkPdf(id));
      this.scopePdf = response.data;
    },
  },
});
