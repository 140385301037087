<template>
  <div class="flex justify-between items-center mb-6">
    <h3 class="text-body1 leading-5 font-medium">
      {{ !props.isNotRelatedProduct ? t("recommendedProducts.productsFrom") : t("recommendedProducts.recommendedProducts") }}
      {{ props.partnerName }}
    </h3>
    <app-button
      id="google-add-all-products"
      data-cy="button-add-all-products"
      name="addAll"
      text="Add all"
      size="medium"
      :color="isAddAllDisabled ? 'disabled' : 'outline'"
      :is-disabled="isAddAllDisabled"
      :text-color="isAddAllDisabled ? 'white' : 'black'"
      :style="`roundedFull`"
      class="min-w-[115px]"
      @click="addAll"
    >
      <template #icon>
        <PlusIcon :class="isAddAllDisabled ? 'text-white w-5 h-5 ml-2' : 'text-black w-5 h-5 ml-2'" />
      </template>
      >
    </app-button>
  </div>
  <recommended-products-card
    v-for="product in getFiveProducts"
    :key="product.id"
    :partner-product-logo="props.partnerProductLogo"
    :data="product"
    :is-directory-modal="true"
    @on-enquire-click="onEnquireClick"
  ></recommended-products-card>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { storeToRefs } from "pinia";
import { useScopeProductStore } from "@/stores/scope-product";

import { RecommendedProduct } from "@/stores/form/types";
import { ScopeProductItem } from "@/stores/scope-product/types";

import i18n from "@/plugins/vue-i18n";

import RecommendedProductsCard from "@/components/recommended-products/RecommendedProductsCard.vue";
import AppButton from "@/components/ui/buttons/AppButton.vue";

import PlusIcon from "@/components/brand/icons/PlusIcon.vue";

import { addAllProducts } from "@/common/utils/product/related-products-utils";
import { RelatedProduct } from "@/stores/directories/types";

const scopeProductStore = useScopeProductStore();

const { scopeProducts } = storeToRefs(scopeProductStore);

const { t } = i18n.global;

const props = defineProps({
  data: {
    type: Array as () => RelatedProduct[],
    required: true,
  },
  partnerName: {
    type: String,
  },
  partnerProductLogo: {
    type: String,
  },
  isNotRelatedProduct: {
    type: Boolean,
  },
  isDirectoryModal: {
    type: Boolean,
    default: false,
  },
});

const isAddAllDisabled = ref(false);

const getFiveProducts = computed(() => props.data?.slice(0, 5) || []);

const addAll = () => {
  if (isAddAllDisabled.value === false) {
    addAllProducts(getFiveProducts.value as RecommendedProduct[]);
  }
  isAddAllDisabled.value = true;
};

const lengthOfProductsToAdd = () => {
  let lengthOfAddedProducts = 0;
  const scopeProductStore = useScopeProductStore();

  const { scopeProducts } = storeToRefs(scopeProductStore);

  if (props.data) {
    const enquiredProductIds = scopeProducts.value.scopeProducts.map((item) => item.product.id);
    const productIds = props.data.map((item) => item.id);

    productIds.forEach((id: number) => {
      const foundInEnquiredProducts = enquiredProductIds.find((enquireId) => enquireId === id);
      if (foundInEnquiredProducts) {
        lengthOfAddedProducts++;
      }
    });
  }
  return lengthOfAddedProducts;
};

async function onEnquireClick(emittedProductId: number) {
  const productIsAlreadyEnquired = !!scopeProducts.value?.scopeProducts?.some(
    (scopeProduct: ScopeProductItem) => scopeProduct.product.id === emittedProductId,
  );
  if (productIsAlreadyEnquired) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const scopeProductItem = scopeProducts.value?.scopeProducts.find(
      (scopeProduct: ScopeProductItem) => scopeProduct.product.id === emittedProductId,
    );
    if (scopeProductItem) {
      await scopeProductStore.deleteScopeProduct(scopeProductItem.id);
    }
  } else {
    await scopeProductStore.saveScopeProduct({
      scopeId: scopeProducts.value.id !== 0 ? scopeProducts.value.id : null,
      productIds: [emittedProductId],
    });
  }
  await scopeProductStore.getScopeProducts();

  if (props.data?.length - lengthOfProductsToAdd() === 0) {
    isAddAllDisabled.value = true;
  } else if (props.data?.length - lengthOfProductsToAdd() > 0) {
    isAddAllDisabled.value = false;
  }
}

onMounted(() => {
  if (props.data?.length - lengthOfProductsToAdd() === 0) {
    isAddAllDisabled.value = true;
  }
});
</script>
