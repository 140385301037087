import { defineStore } from "pinia";
import { createActionHelpers } from "@/stores/helpers/global-loader";
import { Product, ProductState } from "./types";
import { getProducts, getProduct } from "@/api/product/product-api";
import { ProductPaging } from "@/api/product/types";

const { startLoading } = createActionHelpers();

export const useProductStore = defineStore("product", {
  state: (): ProductState => ({
    product: null,
    products: [],
    productCategoryFilterId: null,
    paginationData: null,
    paginationFilter: { PageNo: 1, PageSize: 10, ProductSubcategoryId: [], ProductCategoryId: 0, Recommended: false, SearchText: "" },
  }),
  actions: {
    async getProducts(productPaging: ProductPaging) {
      this.products = [];
      const response = await getProducts(productPaging);
      this.paginationData = response.data.paging;
      this.products = response.data.data;
    },
    async getProduct(id: number) {
      this.product = {} as Product;
      const response = await getProduct(id);
      this.product = response.data.data;
    },
    setProductCategoryFilter(selectedProductCategoryId: number | null) {
      this.productCategoryFilterId = selectedProductCategoryId;
      this.paginationFilter = {
        PageNo: 1,
        PageSize: 10,
        ProductSubcategoryId: [],
        ProductCategoryId: selectedProductCategoryId,
        Recommended: false,
        SearchText: "",
      };
    },
  },
});
