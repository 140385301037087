import { storeToRefs } from "pinia";
import { useScopeProductStore } from "@/stores/scope-product";

import { RecommendedProduct } from "@/stores/form/types";
import { RelatedProduct } from "@/stores/directories/types";

/**
 * A function to add all products in a related or recommended product list
 * @param { array } products - The related or recommended products
 */
export function addAllProducts(products: RecommendedProduct[] | RelatedProduct[]): void {
  const scopeProductStore = useScopeProductStore();

  const { scopeProducts } = storeToRefs(scopeProductStore);

  scopeProductStore.getScopeProducts();

  const enquiredProductIds = scopeProducts.value?.scopeProducts?.map((item) => item.product.id);
  const productIds = products.map((item) => item.id);

  const productsToBeAdded = [
    ...enquiredProductIds.filter((id) => !productIds.includes(id)),
    ...productIds.filter((id) => !enquiredProductIds.includes(id)),
  ];

  scopeProductStore
    .saveScopeProduct({
      scopeId: scopeProducts.value.id !== 0 ? scopeProducts.value.id : null,
      productIds: productsToBeAdded,
    })
    .then(() => scopeProductStore.getScopeProducts());
}
