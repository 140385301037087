<template>
  <div class="min-h-full" :class="[mobileMenuIsActive ? 'fixed' : '']">
    <main>
      <div class="relative flex flex-col md:flex-row">
        <app-banner
          :header-text="customBannerName === '' ? bannerObject?.headerText : customBannerName"
          :profile-toolbar="bannerObject?.showProfileDropdown"
        ></app-banner>
        <app-navigation @updateMenuState="setMobileMenuState"></app-navigation>
        <img class="fixed w-full h-[45%] object-cover" :src="GradientCard" alt="" />
        <div class="w-full min-h-screen px-2 sm:px-10 xl:px-20 lg:pl-[300px] xl:pl-[345px] z-10">
          <slot></slot>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import AppNavigation from "@/components/ui/AppNavigation.vue";

import AppBanner from "@/components/ui/AppBanner.vue";
import GradientCard from "@/assets/images/ui/cta-card.svg?url";
import { useRoute } from "vue-router";
import { BannerObject } from "@/stores/app/types";

const route = useRoute();

const bannerObject = computed<BannerObject>(() => route.meta?.banner as BannerObject);

const mobileMenuIsActive = ref(false);

const customBannerName = computed(() => {
  if (route.name === "my-proposal") {
    return route.params.proposalName;
  } else if (route.name === "generic-start" || route.name === "generic-form" || route.name === "generic-end") {
    return `${route.params.flowName[0].toUpperCase()}${route.params.flowName.slice(1)}`;
  }

  return "";
});

const setMobileMenuState = (value: boolean): void => {
  mobileMenuIsActive.value = value;
};
</script>
