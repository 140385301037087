class ObjectUtils {
  /**
   * It takes an object, converts it to JSON, then converts it back to an object
   * @param {T} toClone - The object to clone.
   * @returns A deep clone of the object passed in.
   */
  public static DeepClone<T>(toClone: T): T {
    return JSON.parse(JSON.stringify(toClone)) as T;
  }
}

export default ObjectUtils;
