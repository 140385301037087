import locales from "./locales";
import { navigation, userNavigation } from "./navigation";
import sentry from "./sentry";

export default {
  // product display information
  app: {
    name: "Delta Vue Boilerplate",
    version: "0.0.1",
  },

  // sentry configs
  sentry,

  // locales
  locales,

  // navigation
  navigation,
  userNavigation,
};
