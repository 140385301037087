import { AxiosResponse } from "axios";
import { createAxiosRequest } from "../api-base";

const resource = "flows";

/**
 * Fetches all the dynamic flows for the given application that has been set up in Retool
 * @returns An AxiosResponse object
 */
export async function getFlow(flowId: number): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).get("/", { params: { flowId: flowId } });
}

export async function getDefaultFlow(): Promise<AxiosResponse> {
  return createAxiosRequest(resource, 1).get("/");
}
