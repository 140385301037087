import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../configs/index";
import { ResolvableTo, ScreensConfig } from "tailwindcss/types/config";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const fullConfig = resolveConfig(tailwindConfig);

/**
 * It takes the breakpoint value and returns the numeric value
 * @param {string} value - breakpoint string value in px
 * @returns returns the number value of the breakpoint
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const getBreakpointValue = (value: string): number => +fullConfig.theme.screens[value].slice(0, fullConfig.theme.screens[value].indexOf("px"));

/**
 * It calculates the current breakpoint based on the width of the browser window
 * @returns returns the string value of the current breakpoint
 */

export const getBreakpoint = () => {
  let currentBreakpoint = "";
  let biggestBreakpointValue = 0;

  if (fullConfig.theme) {
    const screens = fullConfig.theme.screens;

    for (const breakpoint of Object.keys(screens as ResolvableTo<ScreensConfig>)) {
      const breakpointValue = getBreakpointValue(breakpoint);

      if (breakpointValue > biggestBreakpointValue && window.innerWidth >= breakpointValue) {
        biggestBreakpointValue = breakpointValue;
        currentBreakpoint = breakpoint;
      }
    }
  }
  return currentBreakpoint;
};
