<template>
  <div class="grid grid-cols-[50px_1fr_50px] bg-background-light-light px-22px py-13px rounded-15px cursor-pointer" @click="openProductModal">
    <div class="flex place-self-center border-r border-r-background-light-light-2 pr-5 h-10">
      <img :src="props.data.product.logoUrl || logoPlaceholder" alt="product-icon" />
    </div>
    <div class="px-5">
      <h6 class="text-greyscale-dark-5 mb-5px font-medium">{{ props.data.product.name }}</h6>
      <p class="text-postscript text-text-light-7-1 line-clamp-2" v-html="props.data.product.shortDescription"></p>
    </div>
    <app-button-icon
      :id="`google-remove-${convertToLowercaseWithDashes(props.data.product.name)}-from-enquires`"
      data-cy="button-remove-product-from-enquiries"
      :button-size="ButtonSize.MEDIUM"
      :button-colour="ButtonColours.GRAY"
      :icon-image="trashIcon"
      class="place-self-center rounded-full"
      @click.stop="removeProductFromEnquiries()"
    ></app-button-icon>
  </div>
  <app-product-modal ref="productModal" :data="props.data.product"></app-product-modal>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { useScopeProductStore } from "@/stores/scope-product";

import { convertToLowercaseWithDashes } from "@/common/utils/string-utils";

import AppButtonIcon from "@/components/ui/buttons/AppButtonIcon.vue";
import AppProductModal from "@/components/products/product/AppProductModal.vue";

import { ButtonColours, ButtonSize } from "@/common/constants/buttons";

import { ScopeProductItem } from "@/stores/scope-product/types";

import trashIcon from "@/assets/images/icons/trash-can-black.svg?url";
import logoPlaceholder from "@/assets/images/logos/logo-placeholder-transparent.svg";

import i18n from "@/plugins/vue-i18n";

const props = defineProps({
  data: {
    type: Object as () => ScopeProductItem,
    required: true,
  },
});

const scopeProductStore = useScopeProductStore();
const productModal = ref();

async function removeProductFromEnquiries() {
  await scopeProductStore.deleteScopeProduct(props.data.id);
  await scopeProductStore.getScopeProducts();
}

async function openProductModal() {
  productModal.value.openProductModal();
}
</script>
