<template>
  <div class="p-6 absolute bg-background-delta">
    <app-button-icon
      data-cy="button-close-enquire-modal"
      :button-size="ButtonSize.LARGE"
      :button-colour="ButtonColours.WHITE"
      :icon-image="closeIcon"
      @click="closeModal"
    ></app-button-icon>
  </div>

  <div class="p-6 px-25px flex flex-col items-center justify-center h-full md:px-[100px]">
    <img :src="thankYouCheckIcon" alt="green block with check mark to simulate that the enquiry was successful" class="mb-18px" />
    <h3 class="mb-18px text-center font-medium">{{ t("enquire.thankYouText") }}</h3>
    <h5 class="text-text-light-4.5-1 text-center pb-10">{{ t("enquire.thankYouContactText") }}</h5>
    <app-button
      id="google-button-to-dashboard"
      data-cy="button-to-dashboard"
      name="to-dashboard"
      :text="t('general.goToDashboard')"
      size="medium"
      :color="`black`"
      :text-color="`white`"
      :style="`roundedFull`"
      @click="toDashboard"
    />
  </div>
</template>

<script setup lang="ts">
import AppButtonIcon from "@/components/ui/buttons/AppButtonIcon.vue";

import AppButton from "@/components/ui/buttons/AppButton.vue";

import { ButtonColours, ButtonSize } from "@/common/constants/buttons";

import closeIcon from "@/assets/images/icons/close.svg?url";
import thankYouCheckIcon from "@/assets/images/icons/thank-you/thank-you-check.svg?url";
import router from "@/router";

import i18n from "@/plugins/vue-i18n";

const { t } = i18n.global;

const emits = defineEmits(["closeModal"]);

function closeModal() {
  emits("closeModal");
}

function toDashboard() {
  emits("closeModal");
  router.push({ name: "dashboard" });
}
</script>
