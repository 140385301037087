import { defineStore } from "pinia";
import { createActionHelpers } from "@/stores/helpers/global-loader";
import { AuthState } from "./types";
import { PayloadData } from "@/api/auth/types";
import { authenticateMagicLogin } from "@/api/auth/auth-api";

const { startLoading } = createActionHelpers();

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    authenticationData: JSON.parse(localStorage.getItem("authData") ?? "{}"),
  }),
  actions: {
    async authenticateMagicLogin(payload: PayloadData) {
      const tab = sessionStorage.getItem("tab");

      if (tab !== "tab1") {
        const response = await startLoading("auth", authenticateMagicLogin(payload));
        this.authenticationData = response.data.data;
        localStorage.setItem("authData", JSON.stringify(this.authenticationData));
      } else {
        location.reload();
        this.authenticationData = JSON.parse(localStorage.getItem("authData") ?? "{}");
      }
    },
  },
});
