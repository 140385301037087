import router from "@/router";
import { AuthenticationData } from "@/api/auth/types";
import { RouteRecordName } from "vue-router";

type RouteRecordType = RouteRecordName | null | undefined;

/**
 * It takes a route path and returns the route name
 * @param {string} routePath - string - The route path to resolve.
 * @returns The name of the route.
 */
export const getRouteName = (routePath: string): RouteRecordType => {
  const { name } = router.resolve({
    path: routePath,
  });

  return name;
};

/**
 * If the route name is either privacy-policy or non-disclosure-agreement, return true, otherwise
 * return false.
 * @param {RouteRecordName | null | undefined} routeName - The name of the route that is currently
 * being navigated to.
 * @returns A boolean value.
 */
export const isPoliciesRoute = (routeName: RouteRecordType): boolean => {
  return routeName === "privacy-policy" || routeName === "non-disclosure-agreement";
};

/**
 * If the route name is one of the onboarding routes, return true, otherwise return false.
 * @param {RouteRecordName | null | undefined} routeName - The name of the route that the user is
 * currently on.
 * @returns A boolean value.
 */
export const isOnboardingRoute = (routeName: RouteRecordType): boolean => {
  return routeName === "onboarding-venture-profile" || routeName === "welcome" || routeName === "gap-analysis" || routeName === "thank-you";
};

/**
 * If the user has not completed onboarding, redirect them to the onboarding page
 * @param {AuthenticationData | null} authenticationData - This is the data that is returned from the
 * authentication API.
 * @param {RouteRecordName | null | undefined} routeName - The name of the route that the user is
 * trying to access.
 * @returns A boolean
 */
export const hasUserCompletedOnboarding = (authenticationData: AuthenticationData | null, routeName: RouteRecordType): void => {
  if (authenticationData?.ventureProfileDone === false || authenticationData?.ventureOnboarded === false) {
    if (authenticationData?.ventureProfileDone === false) {
      router.push({ name: "welcome" });
    }
  } else if (isOnboardingRoute(routeName) && authenticationData?.ventureProfileDone === true && authenticationData?.ventureOnboarded === true) {
    router.push({ name: "dashboard" });
  }
};

/**
 * If the user is not on a policies route and the route name is not undefined, check if the user has
 * completed onboarding
 * @param {string} routePath - The path of the route that the user is trying to access.
 * @param {AuthenticationData | null} authenticationData - This is the data that is returned from the
 * authentication service.
 */
export const checkMandatoryRouteLogic = (routePath: string, authenticationData: AuthenticationData | null): void => {
  const routeName = getRouteName(routePath);

  if (!isPoliciesRoute(routeName) && routeName !== undefined) {
    hasUserCompletedOnboarding(authenticationData, routeName);
  }
};
