import { Venture } from "@/api/venture/types";
import { Industry } from "@/api/industry/types";
import { FundingStage } from "@/stores/funding-stage/types";
import { VentureBillingData } from "@/stores/venture-billing/types";
import { Paged } from "@/api/types";

export interface VentureState {
  venture: VentureData | null;
  ventureList: Venture[];
  selectedVenture: VentureData;
  ventureCreated: boolean;
  ventureParams: VentureParams;
}

export interface VentureData {
  id: number;
  accountId: string;
  name: string;
  linkedinUrl: string;
  websiteUrl: string;
  description: string;
  shortDescription: string;
  tags: string;
  teamSize: number;
  contactName: string;
  contactEmail: string;
  contactImageUrl: string | null;
  calendlyUrl: string;
  foundedDate: string;
  city: string;
  pitchDeckUrl: string;
  deltaProjectId: number;
  productCategoryName: string;
  industryName: string;
  profileImageUrl: string | null;
  headerImageUrl: string | null;
  country: Country;
  industry: Industry;
  fundingStage: FundingStage;
  privacyAccepted: boolean;
  ndaAccepted: boolean;
  privacyNdaAccepted: string;
  contactType: number;
  statusId: number;
  billing: VentureBillingData | null;
}

export interface Country {
  id: number;
  commonName: string;
  alpha2Code: string;
  currencySymbol: string;
  currencyCode: string;
}

export interface VentureParams extends Paged {
  CountryId?: number;
  FundingStageId?: number;
}

export enum ImageType {
  HEADER = 1,
  IMAGE,
  LOGO,
  CONTACT_PERSON,
  ICON,
  PROFILE,
}
