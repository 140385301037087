import { RouteRecordRaw } from "vue-router";
import i18n from "@/plugins/vue-i18n";

export default [
  {
    path: "/my-proposals",
    name: "my-proposals",
    component: () => import("@/views/GenericViewPage.vue"),
    children: [
      {
        path: "",
        name: "my-proposals-parent",
        component: () => import("@/views/proposals/MyProposals.vue"),
        meta: {
          requiresAuth: true,
          banner: {
            showProfileDropdown: true,
            headerText: `${i18n.global.t(`routes.proposalsPageTitle`)}`,
          },
          navigation: {
            showNavigationItems: true,
          },
        },
      },
      {
        path: "/my-proposals/:id/:proposalName",
        name: "my-proposal",
        component: () => import("@/views/proposals/MyProposal.vue"),
        props: true,
        meta: {
          requiresAuth: true,
          banner: {
            showProfileDropdown: true,
            headerText: "",
          },
          navigation: {
            showNavigationItems: true,
          },
        },
      },
    ],
  },
] as RouteRecordRaw[];
