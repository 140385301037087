<template>
  <div
    :class="[
      isDisabled[isDisabled] && Colors[color] === 'btn-disabled' ? 'p-0.5 bg-grey w-fit' : iconOnly ? 'rounded-10px' : 'rounded-40px',
      Colors[color] === 'btn-outline' ? 'border border-black w-fit' : iconOnly ? 'rounded-10px' : 'rounded-40px',
      Styles[style] === 'rounded-full' ? 'rounded-full' : 'rounded-10px',
      'w-fit',
    ]"
  >
    <button
      :id="name"
      :class="['btn inline-flex items-center justify-center group w-full', iconOnly ? IconSizes[size] : Sizes[size], Colors[color], Styles[style]]"
      :name="name"
      :type="type"
      :disabled="isDisabled"
      @click="onClick()"
    >
      <div v-if="iconOnly">
        <slot name="icon"></slot>
        <span>{{ text }}</span>
      </div>
      <span v-if="!iconOnly" :class="[TextColors[textColor]]">{{ text }}</span>
      <slot v-if="!iconOnly" name="icon"></slot>
    </button>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(["onClick"]);

const Sizes = {
  small: "btn-small",
  medium: "btn-medium",
  large: "btn-large",
};

const IconSizes = {
  small: "btn-icon-small",
  medium: "btn-icon-medium",
  large: "btn-icon-large",
};

const Colors = {
  black: "btn-black",
  white: "btn-white",
  gray: "btn-gray",
  green: "btn-green",
  outline: "btn-outline",
  disabled: "btn-disabled",
};

const TextColors = {
  black: "text-black",
  white: "text-white",
};

const Styles = {
  roundedFull: "rounded-full",
};

interface Props {
  name: string;
  text?: string;
  isDisabled?: boolean;
  iconOnly?: boolean;
  type?: "button" | "submit" | "reset";
  size?: "small" | "medium" | "large";
  color?: "black" | "white" | "gray" | "green" | "outline" | "disabled";
  textColor?: "black" | "white";
  style?: "roundedFull";
}

// eslint-disable-next-line vue/no-setup-props-destructure
const {
  name,
  text = "",
  isDisabled = false,
  iconOnly = false,
  type = "button",
  size = "medium",
  color = "gray",
  textColor = "black",
  style = "roundedFull",
} = defineProps<Props>();

const onClick = () => {
  emits("onClick");
};
</script>
