import { ProfileState, RawQueryParams, UserMetaData } from "./types";
import { defineStore } from "pinia";
import { Magic } from "magic-sdk";
import router from "@/router";

export const useProfileStore = defineStore("profile", {
  state: (): ProfileState => ({
    user: JSON.parse(localStorage.getItem("user") ?? "{}"),
    userToken: localStorage.getItem("userToken") ?? "",
    selectedIndustry: null,
    selectedStage: null,
    selectedCountry: null,
    queryParams: JSON.parse(localStorage.getItem("queryParams") ?? "{}"),
  }),
  actions: {
    async login(userToken: string, userMetaData: UserMetaData) {
      this.user = userMetaData;
      this.userToken = userToken;
      localStorage.setItem("user", JSON.stringify(userMetaData));
      localStorage.setItem("userToken", userToken);
    },
    async logout() {
      const magic = new Magic(import.meta.env.VITE_APP_MAGIC_KEY);
      await magic.user.logout();

      localStorage.removeItem("user");
      localStorage.removeItem("userToken");
      localStorage.removeItem("authData");
      localStorage.removeItem("queryParams");
      localStorage.removeItem("redirect");
      await router.push({ name: "onboarding" });
      location.reload();
    },
    setQueryParams(params: RawQueryParams) {
      this.queryParams = {
        destination: params.destination || "",
        onboarding: params.onboarding || "",
        salesParams: {
          clientId: params.client_id,
          salesRepId: params.sales_rep_id,
          salesRepSlackId: params.sales_rep_slack_id,
        },
      };
      localStorage.setItem("queryParams", JSON.stringify(this.queryParams));
    },
  },
});
